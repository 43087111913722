import React, { useEffect, useState } from "react";
import "../ClientPanel/MainMenu.css";
import { Link } from "react-router-dom";
import PageHeader from "./Components/PageHeader";
import Footer from "./Components/Footer";
import cricketmain from "../img/cricketmain.webp";
import soccermain from "../img/soccermain.png";
import tennismain from "../img/tennismain.webp";
import football from "../img/football.webp";
import banner1 from "../img/banner1.webp";
import banner2 from "../img/banner2.webp";
import banner3 from "../img/banner3.png";
import banner4 from "../img/banner4.png";
import casinomain from "../img/an-le-khanh-O6TMlFbWVNM-unsplash.jpg";
import aviator from "../img/About Aviator in English.jpg";
import colorpridactor from "../img/wheel.png";
import Matka from "../img/satta-matka-king-result-27-december-2023-19.jpg";
import resetpassword from "../img/reset-password.png";
import userprofile from "../img/pngwing.com.png";
import completegame from "../img/pngtree-cricket-game-icon-with-player-in-helmet-holding-bat-png-image_12311287.png";
import myledger from "../img/ledger.png";

export default function MainMenu() {
  const [activeIndex, setActiveIndex] = useState(0);
  const banners = [banner1, banner2, banner3, banner4];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  // Auto-slide logic
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide(); // Slide to the next image
    }, 2300); // Set the auto-slide interval time in milliseconds

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
        <PageHeader />
      </div>

      <div
        id="carouselExampleInterval"
        className="carousel slide carousel-fade"
      >
        <div className="carousel-inner">
          {banners.map((banner, index) => (
            <div
              key={index}
              className={`carousel-item ${
                index === activeIndex ? "active" : ""
              }`}
            >
              <img
                src={banner}
                className="d-block w-100"
                alt={`banner-${index}`}
              />
            </div>
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          onClick={() =>
            setActiveIndex((activeIndex - 1 + banners.length) % banners.length)
          }
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          onClick={nextSlide}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container" style={{ backgroundColor: "black" }}>
        <h1 id="Live_Sports" className="text-center pt-4">
          Live Sports
        </h1>
        <div className="row">
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/user-inplay">
              <img src={cricketmain} alt="Cricket" className="img-fluid" />
              <div className="GameList" style={{ color: "white" }}>
                Cricket
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/MainMenu">
              <div className="hover-container">
                <img
                  src={soccermain}
                  alt="Soccer"
                  className="img-fluid hover-image"
                />
                <div className="hover-overlay">Coming Soon</div>
              </div>
              <div className="GameList" style={{ color: "white" }}>
                Soccer
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/MainMenu">
              <div className="hover-container">
                <img
                  src={tennismain}
                  alt="Tennis"
                  className="img-fluid hover-image"
                />
                <div className="hover-overlay">Coming Soon</div>
              </div>
              <div className="GameList" style={{ color: "white" }}>
                Tennis
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/MainMenu">
              <div className="hover-container">
                <img
                  src={football}
                  alt="Football"
                  className="img-fluid hover-image"
                />
                <div className="hover-overlay">Coming Soon</div>
              </div>
              <div className="GameList" style={{ color: "white" }}>
                Football
              </div>
            </Link>
          </div>
        </div>

        <h1 id="Live_Sports" className="text-center pt-4 pb-4">
          Virtual Games
        </h1>
        <div className="row">
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/user-casino">
              <img
                src={casinomain}
                alt="Casino"
                className="img-fluid"
                style={{ height: "151px", width: "227px" }}
                id="imgresponsivesize"
              />
              <div className="GameList" style={{ color: "white" }}>
                Casino
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/MainMenu">
              <div className="hover-container">
                <img
                  src={aviator}
                  alt="Aviator"
                  className="img-fluid hover-image"
                  id="imgresponsivesize"
                  style={{ height: "151px", width: "227px" }}
                />
                <div className="hover-overlay">Coming Soon</div>
              </div>
              <div className="GameList" style={{ color: "white" }}>
                Aviator
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/MainMenu">
              <div className="hover-container">
                <img
                  src={colorpridactor}
                  alt="Color Prediction"
                  className="img-fluid hover-image"
                  id="imgresponsivesize"
                  style={{ height: "151px", width: "227px" }}
                />
                <div className="hover-overlay">Coming Soon</div>
              </div>
              <div className="GameList" style={{ color: "white" }}>
                Color Prediction
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/MainMenu">
              <div className="hover-container">
                <img
                  src={Matka}
                  alt="Matka"
                  className="img-fluid hover-image"
                  id="imgresponsivesize"
                  style={{ height: "151px", width: "227px" }}
                />
                <div className="hover-overlay">Coming Soon</div>
              </div>
              <div className="GameList" style={{ color: "white" }}>
                Matka
              </div>
            </Link>
          </div>
        </div>

        <h1 id="Live_Sports" className="text-center pt-4 pb-4">
          Our Details
        </h1>
        <div className="row" style={{ paddingBottom: "72px" }}>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/user-completegame-list">
              <img
                src={completegame}
                alt="Complete Games"
                className="img-fluid"
                style={{ height: "180px", width: "180px" }}
                id="imgresponsivesize"
              />
              <div className="GameList" style={{ color: "white" }}>
                Complete Games
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/user-ledger">
              <img
                src={myledger}
                alt="My Ledger"
                className="img-fluid"
                style={{ height: "180px", width: "180px" }}
                id="imgresponsivesize"
              />
              <div className="GameList" style={{ color: "white" }}>
                My Ledger
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/user-profile">
              <img
                src={userprofile}
                alt="Profile"
                className="img-fluid"
                style={{ height: "180px", width: "180px" }}
                id="imgresponsivesize"
              />
              <div className="GameList" style={{ color: "white" }}>
                Profile
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 mb-4 text-center">
            <Link to="/user-password">
              <img
                src={resetpassword}
                alt="Reset Password"
                className="img-fluid"
                style={{ height: "180px", width: "180px" }}
                id="imgresponsivesize"
              />
              <div className="GameList" style={{ color: "white" }}>
                Reset Password
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
