/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import "./UserBet.css";
import { useLocation } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import { toast } from "react-hot-toast";
import useSocketBetguru from "./hooks/Sockets/useSocketBetguru";
import useSocketIceexch from "./hooks/Sockets/useSocketIceexch";
import useSocketPersonal from "./hooks/Sockets/useSocketPersonal";
import useSocketMatchSettings from "./hooks/Sockets/useSocketMatchSettings";
import useGetSessionTotal from "./hooks/useGetSessionTotal";
import useGetBetting from "./hooks/useGetBetting";
import useGetSessionMatchBets from "./hooks/useGetSessionMatchBets";
import useGetMatchSetting from "../hooks/Games/Inplay/useGetMatchSetting";
import useCreateBet from "./hooks/useCreateBet";
import useCreateSessionBet from "./hooks/useCreateSessionBet";
import { match4, match as matchDummy } from "../cms/gameData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTelevision,
  faXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function UserBet() {
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);

  const matchId = UrlSearchParams.get("id");
  let userId = sessionStorage.getItem("UUID");
  let match_type = sessionStorage.getItem("match_type");
  let tvcode = sessionStorage.getItem("tvcode");
  let tvcodeapi = sessionStorage.getItem("tvcodeapi");
  let team1 = sessionStorage.getItem("team1");
  let team2 = sessionStorage.getItem("team2");
  let team3 = "DRAW";
  let score_code = sessionStorage.getItem("score_code");
  let session_automatic = sessionStorage.getItem("session_automatic");

  const { mutate: getSessionTotal } = useGetSessionTotal();
  const { mutate: getBetting } = useGetBetting();
  const { mutate: getSessionMatchBets } = useGetSessionMatchBets();
  const { mutate: getMatchSetting } = useGetMatchSetting();
  const { mutate: createBet } = useCreateBet();
  const { mutate: createSessionBet } = useCreateSessionBet();

  const [matchCode, setMatchCode] = useState("");
  const [matchApi, setMatchApi] = useState("");

  const [scoreBoardType, setScoreBoardType] = useState("");

  const [showText, setShowText] = useState(false);
  const [sessionProfileLosses, setSessionProfileLosses] = useState(0);
  const [teamBets, setTeamBets] = useState([]);
  const [sessionBets, setSessionBets] = useState([]);
  const [matchSetting, setMatchSetting] = useState(null);
  const [gameSettings, setGameSettings] = useState({});
  const [isGameLocked, setIsGameLocked] = useState(false);
  const [matchComment, setMatchComment] = useState("");
  const [gameSessionSetting, setGameSessionSetting] = useState([]);
  const [matchSessionSetting, setMatchSessionSetting] = useState(null);
  const [team1Position, setTeam1Position] = useState(0);
  const [team2Position, setTeam2Position] = useState(0);
  const [team3Position, setTeam3Position] = useState(0);
  const [team1Yes, setTeam1Yes] = useState("");
  const [team1No, setTeam1No] = useState("");
  const [team2Yes, setTeam2Yes] = useState("");
  const [team2No, setTeam2No] = useState("");
  const [team3Yes, setTeam3Yes] = useState("");
  const [team3No, setTeam3No] = useState("");
  let fancyOdds = [];
  const [fancyOddsData, setFancyOddsData] = useState([]);

  const [teamName1, setTeamName1] = useState("");
  const [teamName2, setTeamName2] = useState("");
  const [tvCodeUrl, setTvCodeUrl] = useState("0");

  const [iceExchData, setIceExchData] = useState([]);
  const [betGuruData, setBetGuruData] = useState([]);
  const [personalData, setPersonalData] = useState([]);

  const [betAmount, setBetAmount] = useState(0);
  const [matchBetPayload, setMatchBetPayload] = useState({});
  const [timeLeft, setTimeLeft] = useState("");
  const intervalIdRef = useRef(null);
  const [betAllowed, setBetAllowed] = useState(false);
  const [sessionBetPayload, setSessionBetPayload] = useState({});
  const [betType, setBetType] = useState("");
  const [placeBetLoader, setPlaceBetLoader] = useState(false);
  const [betData, setBetData] = useState([]);

  const [matchId1, setMatchId1] = useState(0);
  const [matchId2, setMatchId2] = useState(0);
  const [matchId4, setMatchId4] = useState(0);

  const [sessionMinAmt, setSessionMinAmt] = useState(0);
  const [sessionMaxAmt, setSessionMaxAmt] = useState(0);
  const [matchMinAmt, setMatchMinAmt] = useState(0);
  const [matchMaxAmt, setMatchMaxAmt] = useState(0);
  const [rateMeter, setRateMeter] = useState(0);

  const betguruData = useSocketBetguru(matchId1);
  const iceexchData = useSocketIceexch(matchId2);
  const personalapiData = useSocketPersonal(matchId4);
  const matchSettingsDataSocket = useSocketMatchSettings(matchId);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const amountData = [500, 1000, 2000, 5000, 10000, 20000, 30000, 50000];

  function getTeamMatchBets() {
    const payload = {
      user_id: userId,
      match_id: matchId,
    };

    let team1_position = 0;
    let team2_position = 0;
    let team3_position = 0;

    getBetting(payload, {
      onSuccess: (data) => {
        let filteredData = data.data.filter(
          (bet) => bet.trash === null || bet.trash === false
        );

        filteredData.forEach((el, index) => {
          team1_position =
            team1_position + el.teamA_bet_amount + el.teamA_lay_amount;
          team2_position =
            team2_position + el.teamB_bet_amount + el.teamB_lay_amount;
          team3_position =
            team3_position + el.draw_bet_amount + el.draw_lay_amount;

          if (el.type === "back") {
            filteredData[index].mode = "L";
            if (el.betTeam === "A") {
              filteredData[index].team = team1;
              filteredData[index].rate = el.teamA_back_rate;
            } else {
              filteredData[index].team = team2;
              filteredData[index].rate = el.teamB_back_rate;
            }
          } else {
            filteredData[index].mode = "K";
            if (el.betTeam === "A") {
              filteredData[index].team = team1;
              filteredData[index].rate = el.teamA_lay_rate;
            } else {
              filteredData[index].team = team2;
              filteredData[index].rate = el.teamB_lay_rate;
            }
          }
        });

        setTeam1Position(team1_position);
        setTeam2Position(team2_position);
        setTeam3Position(team3_position);
        setTeamBets(filteredData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  const handleGetSessionMatchBets = () => {
    const payload = {
      user_id: userId,
      match_id: matchId,
    };

    getSessionMatchBets(payload, {
      onSuccess: (data) => {
        let filteredData = [];

        // eslint-disable-next-line array-callback-return
        data.data.data.filter((item) => {
          if (item.view === true) {
            if (item.trash === false || item.trash === null) {
              filteredData.push(item);
            }
          }
        });
        setSessionBets(filteredData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const handleMatchSessionSettingsData = (data) => {
    const gameDetails = data.gameDetails;
    const gameSettings = data.gameSettings;
    const sessionSettings = data.sessionSettings;

    if (gameDetails === null || gameDetails === undefined) {
      toast.error("Game details not found.");
      return;
    }

    if (gameDetails !== null) {
      setMatchApi(gameDetails.api);
      setMatchCode(gameDetails.code);
      setGameSettings(gameSettings);
      setIsGameLocked(gameSettings.isLocked);
      setMatchComment(gameSettings.pannel_message);
      setMatchMinAmt(gameSettings.match_min);
      setMatchMaxAmt(gameSettings.match_max);
      setSessionMinAmt(gameSettings.session_min);
      setSessionMaxAmt(gameSettings.session_max);
      setRateMeter(gameSettings.rate_meter);
      setGameSessionSetting(sessionSettings);
    }
  };

  const getGameDetails = (data) => {
    let team_1_yes = 0;
    let team_2_yes = 0;

    if (match_type !== "Test" && data.length > 0) {
      setTeamName1(data[0].markets[0].runners[0].runnerName);
      setTeamName2(data[0].markets[0].runners[1].runnerName);
      if (gameSettings.match_bet_lock) {
        setTeam1Yes("0");
        setTeam2Yes("0");
        setTeam3Yes("0");
        setTeam1No("0");
        setTeam2No("0");
        setTeam3No("0");
      } else {
        team_1_yes =
          data[0].markets[0].runners[0].backOdds > 99 ||
          data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].backOdds + "";

        team_2_yes =
          data[0].markets[0].runners[1].backOdds > 99 ||
          data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].backOdds + "";

        if (team_1_yes - rateMeter * 100 < 4) {
          team_1_yes = 0;
          setTeam1Yes(0);
        } else {
          team_1_yes = team_1_yes - rateMeter * 100;
          setTeam1Yes(team_1_yes);
        }

        if (team_2_yes - rateMeter * 100 < 4) {
          team_2_yes = 0;
          setTeam2Yes(0);
        } else {
          team_2_yes = team_2_yes - rateMeter * 100;
          setTeam2Yes(team_2_yes);
        }

        setTeam1No(
          data[0].markets[0].runners[0].layOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].layOdds + ""
        );
        setTeam2No(
          data[0].markets[0].runners[1].layOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].layOdds + ""
        );
      }
    }

    if (match_type === "Test" && data.length > 0) {
      setTeamName1(data[0].markets[0].runners[0].runnerName);
      setTeamName2(data[0].markets[0].runners[1].runnerName);
      if (gameSettings.match_bet_lock) {
        setTeam1Yes("0");
        setTeam2Yes("0");
        setTeam3Yes("0");
        setTeam1No("0");
        setTeam2No("0");
        setTeam3No("0");
      } else {
        team_1_yes =
          data[0].markets[0].runners[0].backOdds > 99 ||
          data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].backOdds + "";

        team_2_yes =
          data[0].markets[0].runners[1].backOdds > 99 ||
          data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].backOdds + "";

        if (team_1_yes - rateMeter * 100 < 4) {
          team_1_yes = 0;
          setTeam1Yes(0);
        } else {
          team_1_yes = team_1_yes - rateMeter * 100;
          setTeam1Yes(team_1_yes);
        }

        if (team_2_yes - rateMeter * 100 < 4) {
          team_2_yes = 0;
          setTeam2Yes(0);
        } else {
          team_2_yes = team_2_yes - rateMeter * 100;
          setTeam2Yes(team_2_yes);
        }

        setTeam1No(
          data[0].markets[0].runners[0].layOdds > 99 ||
            data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[0].layOdds + ""
        );
        setTeam2No(
          data[0].markets[0].runners[1].layOdds > 99 ||
            data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : data[0].markets[0].runners[1].layOdds + ""
        );
      }

      setTeam3Yes(
        data[0].markets[0].runners[2].backOdds > 99
          ? "0"
          : data[0].markets[0].runners[2].backOdds
      );
      setTeam3No(
        data[0].markets[0].runners[2].layOdds > 99
          ? "0"
          : data[0].markets[0].runners[2].layOdds
      );
    }

    fancyOdds = [];
    let fancyOddsIndex = data.length < 3 ? 1 : 2;

    if (match_type !== "Test") {
      if (session_automatic === "true") {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });
        }

        gameSessionSetting.forEach((ele) => {
          if (
            fancyOdds.some(
              (elm) => elm.marketType == ele.session_name && ele.allow
            )
          ) {
            const index = fancyOdds.findIndex(
              (elen) => elen.marketType === ele.session_name
            );
            fancyOdds[index].runners[0].rnrBallRunning = true;
          }
        });
      } else {
        if (data.length > 1) {
          data[fancyOddsIndex].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    } else {
      if (session_automatic === "true") {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              fancyOdds.push(el);
            }
          });

          gameSessionSetting.forEach((ele) => {
            if (
              fancyOdds.some(
                (elm) => elm.marketType === ele.session_name && ele.allow
              )
            ) {
              const index = fancyOdds.findIndex(
                (elen) => elen.marketType === ele.session_name
              );
              fancyOdds[index].runners[0].rnrBallRunning = true;
            }
          });
        }
      } else {
        if (data.length > 0) {
          data[0].markets.forEach((el) => {
            if (el.runners !== undefined) {
              if (
                gameSessionSetting.some(
                  (elem) =>
                    elem.session_name === el.marketType && elem.allow === true
                )
              ) {
                fancyOdds.push(el);
              }
            }
          });
        }
      }
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails3 = (data) => {
    let team_1_yes = 0;
    let team_2_yes = 0;
    let team_3_yes = 0;

    if (gameSettings.match_bet_lock) {
      setTeam1Yes("0");
      setTeam2Yes("0");
      setTeam3Yes("0");
      setTeam1No("0");
      setTeam1No("0");
      setTeam3No("0");
    } else {
      setTeam1Yes(
        data.market[0].events[0].BackPrice1 == "0.00" ||
          data.market[0].events[0].BackPrice1 == "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[0].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[0].BackPrice1.split(".")[1])
      );

      team_1_yes =
        data.market[0].events[0].BackPrice1 == "0.00" ||
        data.market[0].events[0].BackPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[0].BackPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[0].BackPrice1.split(".")[1]);

      setTeam2Yes(
        data.market[0].events[1].BackPrice1 == "0.00" ||
          data.market[0].events[1].BackPrice1 == "0.0"
          ? "0"
          : "0." +
              (data.market[0].events[1].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[1].BackPrice1.split(".")[1])
      );

      team_2_yes =
        data.market[0].events[1].BackPrice1 == "0.00" ||
        data.market[0].events[1].BackPrice1 == "0.0"
          ? "0"
          : "0." +
            (data.market[0].events[1].BackPrice1.split(".").length == 1
              ? "0"
              : data.market[0].events[1].BackPrice1.split(".")[1]);

      if (match_type === "Test") {
        setTeam3Yes(
          data.market[0].events[2].BackPrice1 == "0.00" ||
            data.market[0].events[2].BackPrice1 == "0.0"
            ? "0"
            : "0." +
                (data.market[0].events[2].BackPrice1.split(".").length == 1
                  ? "0"
                  : data.market[0].events[2].BackPrice1.split(".")[1])
        );

        team_3_yes =
          data.market[0].events[2].BackPrice1 == "0.00" ||
          data.market[0].events[2].BackPrice1 == "0.0"
            ? "0"
            : "0." +
              (data.market[0].events[2].BackPrice1.split(".").length == 1
                ? "0"
                : data.market[0].events[2].BackPrice1.split(".")[1]);
      }

      let rate_meter =
        gameSettings === null || gameSettings === undefined ? 0 : rateMeter;

      if (rate_meter === 0 && rate_meter === undefined) {
        setTeam1No(team_1_yes + rate_meter);
        setTeam2No(team_2_yes + rate_meter);
        if (match_type === "Test") {
          setTeam3No(team_3_yes + rate_meter);
        }
      } else {
        setTeam1No(
          data.market[0].events[0].LayPrice1 == "0.00" ||
            data.market[0].events[0].LayPrice1 == "0.0"
            ? "0"
            : "0." +
                (data.market[0].events[0].LayPrice1.split(".").length == 1
                  ? "0"
                  : data.market[0].events[0].LayPrice1.split(".")[1])
        );

        setTeam2No(
          data.market[0].events[1].LayPrice1 == "0.00" ||
            data.market[0].events[1].LayPrice1 == "0.0"
            ? "0"
            : "0." +
                (data.market[0].events[1].LayPrice1.split(".").length == 1
                  ? "0"
                  : data.market[0].events[1].LayPrice1.split(".")[1])
        );

        if (match_type === "Test") {
          setTeam3No(
            data.market[0].events[2].LayPrice1 == "0.00" ||
              data.market[0].events[2].LayPrice1 == "0.0"
              ? "0"
              : "0." +
                  (data.market[0].events[2].LayPrice1.split(".").length == 1
                    ? "0"
                    : data.market[0].events[2].LayPrice1.split(".")[1])
          );
        }
      }
    }

    fancyOdds = [];

    if (data.session == null) {
      return;
    }

    if (session_automatic === "true") {
      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) == 0,
          },
        ];
        fancyOdds.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      gameSessionSetting.forEach((ele) => {
        if (
          fancyOdds.some(
            (elm) => elm.marketType == ele.session_name && ele.allow
          )
        ) {
          const index = fancyOdds.findIndex(
            (elen) => elen.marketType == ele.session_name
          );
          fancyOdds[index].runners[0].rnrBallRunning = true;
        }
      });
    } else {
      fancyOdds = [];
      let allSessions = [];

      data.session.forEach((el) => {
        const runs = [
          {
            id: el.SelectionId,
            layRuns: el.LayPrice1,
            layOdds: Number(el.LaySize1),
            backRuns: el.BackPrice1,
            backOdds: el.BackSize1,
            rnrBallRunning: Number(el.LaySize1) == 0,
          },
        ];

        allSessions.push({
          marketType: el.RunnerName,
          runners: runs,
        });
      });

      allSessions.forEach((el) => {
        if (el.runners !== undefined) {
          if (
            gameSessionSetting.some(
              (elem) =>
                elem.session_name == el.marketType && elem.allow === true
            )
          ) {
            fancyOdds.push(el);
          }
        }
      });
    }

    setFancyOddsData(fancyOdds);
  };

  const getGameDetails2 = (data) => {
    setBetGuruData(data);
    if (Object.keys(data).length > 0 && Object.keys(data).length !== 4) {
      let match = data.matchOdds;
      let session = data.sessionOdds;
      let score = data.score;

      if (match.length > 0) {
        if (gameSettings.match_bet_lock) {
          setTeam1Yes(0);
          setTeam1No(0);
          setTeam2Yes(0);
          setTeam2No(0);
        } else {
          let team_1_yes = 0;
          let team_2_yes = 0;

          setTeam1No(match[0].lrate);
          setTeam2No(match[1].lrate);

          team_1_yes = match[0].krate;
          team_2_yes = match[1].krate;

          if (team_1_yes - rateMeter < 0.04) {
            team_1_yes = 0;
            setTeam1Yes(0);
          } else {
            team_1_yes = team_1_yes - rateMeter;
            setTeam1Yes(team_1_yes);
          }

          if (team_2_yes - rateMeter < 0.04) {
            team_2_yes = 0;
            setTeam2Yes(0);
          } else {
            team_2_yes = team_2_yes - rateMeter;
            setTeam2Yes(team_2_yes);
          }
        }

        if (match_type === "Test") {
          if (gameSettings.match_bet_lock) {
            setTeam3Yes(0);
            setTeam3No(0);
          } else {
            setTeam3Yes(match[2].krate);
            setTeam3No(match[2].lrate);
          }
        }
      }

      setTeamName1(score?.team1);
      setTeamName2(score?.team2);

      let fancyOdds = [];

      if (session.length > 0) {
        if (session_automatic === "true") {
          session.forEach((el) => {
            let newSession = {
              id: el.id,
              marketType: el.name,
              runners: [
                {
                  backRuns: el.yrun,
                  backOdds: el.yrate * 100,
                  layRuns: el.nrun,
                  layOdds: el.nrate * 100,
                  rnrBallRunning: el.yrate === 0 ? true : false,
                  id: el.id,
                },
              ],
            };

            fancyOdds.push(newSession);
          });

          setFancyOddsData(fancyOdds);
        } else {
          session.forEach((el) => {
            if (
              gameSessionSetting.some(
                (elem) => elem.session_name === el.name && elem.allow === true
              )
            ) {
              let newSession = {
                id: el.id,
                marketType: el.name,
                runners: [
                  {
                    backRuns: el.yrun,
                    backOdds: el.yrate * 100,
                    layRuns: el.nrun,
                    layOdds: el.nrate * 100,
                    rnrBallRunning: el.yrate === 0 ? true : false,
                    id: el.id,
                  },
                ],
              };

              fancyOdds.push(newSession);
            }
          });

          setFancyOddsData(fancyOdds);
        }
      }
    }
  };

  const getGameDetails4 = (data) => {
    let match = [];
    if (data.bookMakerOdds.length > 0) {
      match = data.bookMakerOdds[0].bm1;
    }
    let session = data.fancyOdds;

    if (match?.oddDatas?.length > 0) {
      setTeamName1(match.oddDatas[0].rname);
      setTeamName2(match.oddDatas[1].rname);

      if (gameSettings.match_bet_lock) {
        setTeam1Yes(0);
        setTeam1No(0);
        setTeam2Yes(0);
        setTeam2No(0);
        setTeam3Yes(0);
        setTeam3No(0);
      } else {
        let team_1_yes = 0;
        let team_2_yes = 0;
        let team_3_yes = 0;

        setTeam1No(
          parseFloat(match.oddDatas[0].l1) > 99
            ? 0
            : parseFloat(match.oddDatas[0].l1)
        );

        setTeam2No(
          parseFloat(match.oddDatas[1].l1) > 99
            ? 0
            : parseFloat(match.oddDatas[1].l1)
        );

        team_1_yes =
          parseFloat(match.oddDatas[0].b1) > 99
            ? 0
            : parseFloat(match.oddDatas[0].b1);
        team_2_yes =
          parseFloat(match.oddDatas[1].b1) > 99
            ? 0
            : parseFloat(match.oddDatas[1].b1);

        if (match_type === "Test") {
          setTeam3No(
            parseFloat(match.oddDatas[2].l1) > 99
              ? 0
              : parseFloat(match.oddDatas[2].l1)
          );

          team_3_yes =
            parseFloat(match.oddDatas[2].b1) > 99
              ? 0
              : parseFloat(match.oddDatas[2].b1);
        } else {
          setTeam3No(0);
          team_3_yes = 0;
        }

        if (team_1_yes - rateMeter < 0.04) {
          team_1_yes = 0;
          setTeam1Yes(0);
        } else {
          team_1_yes = team_1_yes - rateMeter;
          setTeam1Yes(team_1_yes);
        }

        if (team_2_yes - rateMeter < 0.04) {
          team_2_yes = 0;
          setTeam2Yes(0);
        } else {
          team_2_yes = team_2_yes - rateMeter;
          setTeam2Yes(team_2_yes);
        }

        if (match_type === "Test") {
          if (team_3_yes - rateMeter < 0.04) {
            team_3_yes = 0;
            setTeam3Yes(0);
          } else {
            team_3_yes = team_3_yes - rateMeter;
            setTeam3Yes(team_3_yes);
          }
        } else {
          setTeam3Yes(0);
        }
      }
    }

    let fancyOdds = [];

    if (session.length > 0) {
      if (isGameLocked) {
        setFancyOddsData([]);
      } else {
        if (session_automatic === "true") {
          for (let i = 0; i < session.length; i++) {
            if (session[i].gtype === "Ball By Ball") {
              continue;
            } else {
              session[i].oddDatas.forEach((el) => {
                let newSession = {
                  id: el.sid,
                  marketType: el.rname,
                  remark: el.remark,
                  runners: [
                    {
                      backRuns: Math.round(parseFloat(el.b1)),
                      backOdds: parseFloat(el.bs1),
                      layRuns: Math.round(parseFloat(el.l1)),
                      layOdds: parseFloat(el.ls1),
                      rnrBallRunning: parseFloat(el.b1) === 0 ? true : false,
                      id: el.sid,
                    },
                  ],
                };

                if (String(el.rname).endsWith("2")) {
                  if (match_type === "Test") {
                    fancyOdds.push(newSession);
                  } else {
                    return;
                  }
                } else if (String(el.rname).endsWith("3")) {
                  if (match_type === "Test") {
                    fancyOdds.push(newSession);
                  } else {
                    return;
                  }
                } else if (String(el.rname).includes(".")) {
                  return;
                } else {
                  fancyOdds.push(newSession);
                }
              });
            }
          }
          setFancyOddsData(fancyOdds);
        } else {
          for (let i = 0; i < session.length; i++) {
            if (session[i].gtype === "Ball By Ball") {
              continue;
            } else {
              session[i].oddDatas.forEach((el) => {
                if (
                  gameSessionSetting.some(
                    (elem) =>
                      elem.session_name === el.rname && elem.allow === true
                  )
                ) {
                  let newSession = {
                    id: el.sid,
                    marketType: el.rname,
                    remark: el.remark,
                    runners: [
                      {
                        backRuns: Math.round(parseFloat(el.b1)),
                        backOdds: parseFloat(el.bs1),
                        layRuns: Math.round(parseFloat(el.l1)),
                        layOdds: parseFloat(el.ls1),
                        rnrBallRunning: parseFloat(el.b1) === 0 ? true : false,
                        id: el.sid,
                      },
                    ],
                  };

                  if (String(el.rname).endsWith("2")) {
                    if (match_type === "Test") {
                      fancyOdds.push(newSession);
                    } else {
                      return;
                    }
                  } else if (String(el.rname).endsWith("3")) {
                    if (match_type === "Test") {
                      fancyOdds.push(newSession);
                    } else {
                      return;
                    }
                  } else if (String(el.rname).includes(".")) {
                    return;
                  } else {
                    fancyOdds.push(newSession);
                  }
                }
              });
            }
          }

          setFancyOddsData(fancyOdds);
        }
      }
    }
  };

  const handleGetGameDetailsPaid = (gameCode) => {
    setMatchId2(gameCode);
  };

  const handleGetAppleExch = (gameCode) => {
    setMatchId1(gameCode);
  };

  const handleGetGameDetailsPersonal = (gameCode) => {
    setMatchId4(gameCode);
  };

  const betConverter = (value) => {
    if (matchApi === "2") {
      return value !== "0" && value !== "0.0" && value !== "0.00"
        ? parseFloat(value).toFixed(2)
        : "0";
    } else {
      return value === "0" || value === ""
        ? "0.00"
        : (Number(value) / 100).toFixed(2);
    }
  };

  const placeMatchBet = (payload) => {
    createBet(payload, {
      onSuccess: (data) => {
        toast.success("Bet placed successfully!", {
          duration: 1000,
        });
        setMatchBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }

        getTeamMatchBets();
        handleGetSessionMatchBets();
        setPlaceBetLoader(false);
      },
      onError: (error) => {
        console.log("error", error);
        setMatchBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }
        setPlaceBetLoader(false);
        toast.error("Amount should be less then your wallet balance.", {
          duration: 1000,
        });
      },
    });
  };

  const checkConditions = (matchMin, matchMax, payload) => {
    if (payload.amount < matchMin) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`, {
        duration: 1000,
      });
      return;
    }

    if (payload.amount > matchMax) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`, {
        duration: 1000,
      });
      return;
    }

    let team_1_yes, team_2_yes, team_1_no, team_2_no;

    if (matchCode === "0") {
      if (matchDummy.data.length === 5) {
        team_1_yes =
          matchDummy.data[0].markets[0].runners[0].backOdds > 99 ||
          matchDummy.data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[0].backOdds / 100 + "";
        team_2_yes =
          matchDummy.data[0].markets[0].runners[1].backOdds > 99 ||
          matchDummy.data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[1].backOdds / 100 + "";
        team_1_no =
          matchDummy.data[0].markets[0].runners[0].layOdds > 99 ||
          matchDummy.data[0].markets[0].runners[0].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[0].layOdds / 100 + "";
        team_2_no =
          matchDummy.data[0].markets[0].runners[1].layOdds > 99 ||
          matchDummy.data[0].markets[0].runners[1].rnrBallRunning
            ? "0"
            : matchDummy.data[0].markets[0].runners[1].layOdds / 100 + "";

        if (
          payload.teamA_back_rate == team_1_yes &&
          payload.teamA_lay_rate == team_1_no &&
          payload.teamB_back_rate == team_2_yes &&
          payload.teamB_lay_rate == team_2_no
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.", {
            duration: 1000,
          });
        }
      }
    } else {
      let data = iceExchData;

      if (data.data.length !== 0) {
        team_1_yes =
          data.data[0].markets[0].runners[0].backOdds > 99 ||
          data.data[0].markets[0].runners[0].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[0].backOdds / 100).toFixed(2);
        team_2_yes =
          data.data[0].markets[0].runners[1].backOdds > 99 ||
          data.data[0].markets[0].runners[1].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[1].backOdds / 100).toFixed(2);
        team_1_no =
          data.data[0].markets[0].runners[0].layOdds > 99 ||
          data.data[0].markets[0].runners[0].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[0].layOdds / 100).toFixed(2);
        team_2_no =
          data.data[0].markets[0].runners[1].layOdds > 99 ||
          data.data[0].markets[0].runners[1].rnrBallRunning
            ? "0.00"
            : (data.data[0].markets[0].runners[1].layOdds / 100).toFixed(2);

        if (
          payload.teamA_back_rate == team_1_yes &&
          payload.teamA_lay_rate == team_1_no &&
          payload.teamB_back_rate == team_2_yes &&
          payload.teamB_lay_rate == team_2_no
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.", {
            duration: 1000,
          });
        }
      }
    }
  };

  const checkConditions2 = (matchMin, matchMax, payload) => {
    if (payload.amount < matchMin) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`, {
        duration: 1000,
      });
      return;
    }

    if (payload.amount > matchMax) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`, {
        duration: 1000,
      });
      return;
    }

    let data = betGuruData;

    if (data.length !== 0) {
      if (
        payload.teamA_back_rate == betConverter(data.matchOdds[0].krate) &&
        payload.teamA_lay_rate == betConverter(data.matchOdds[0].lrate) &&
        payload.teamB_back_rate == betConverter(data.matchOdds[1].krate) &&
        payload.teamB_lay_rate == betConverter(data.matchOdds[1].lrate)
      ) {
        placeMatchBet(payload);
      } else {
        toast.error("Rate has changed.", {
          duration: 1000,
        });
      }
    }
  };

  const checkConditions3 = (matchMin, matchMax, payload) => {
    if (payload.amount < matchMin) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`, {
        duration: 1000,
      });
      setBetAllowed(false);
      setTimeLeft("");
      const inputElement = document.querySelector("#MatchAmountInput");
      if (inputElement) {
        inputElement.value = "";
      }
      setPlaceBetLoader(false);
      return;
    }

    if (payload.amount > matchMax) {
      setMatchBetPayload({});
      toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`, {
        duration: 1000,
      });
      setBetAllowed(false);
      setTimeLeft("");
      const inputElement = document.querySelector("#MatchAmountInput");
      if (inputElement) {
        inputElement.value = "";
      }
      setPlaceBetLoader(false);
      return;
    }

    let data = personalData;
    let match = [];
    if (data.bookMakerOdds.length > 0) {
      match = data.bookMakerOdds[0].bm1;
    }

    if (match?.oddDatas?.length > 0) {
      let team_1_yes =
        parseFloat(match.oddDatas[0].b1) > 99
          ? 0
          : parseFloat(match.oddDatas[0].b1);
      let team_2_yes =
        parseFloat(match.oddDatas[1].b1) > 99
          ? 0
          : parseFloat(match.oddDatas[1].b1);
      let team_1_no =
        parseFloat(match.oddDatas[0].l1) > 99
          ? 0
          : parseFloat(match.oddDatas[0].l1);
      let team_2_no =
        parseFloat(match.oddDatas[1].l1) > 99
          ? 0
          : parseFloat(match.oddDatas[1].l1);

      if (team_1_yes - rateMeter < 0.04) {
        team_1_yes = 0;
      } else {
        team_1_yes = team_1_yes - rateMeter;
      }

      if (team_2_yes - rateMeter < 0.04) {
        team_2_yes = 0;
      } else {
        team_2_yes = team_2_yes - rateMeter;
      }

      let team_3_yes = 0;
      let team_3_no = 0;

      if (match_type === "Test") {
        team_3_yes =
          parseFloat(match.oddDatas[2].b1) > 99
            ? 0
            : parseFloat(match.oddDatas[2].b1);
        team_3_no =
          parseFloat(match.oddDatas[2].l1) > 99
            ? 0
            : parseFloat(match.oddDatas[2].l1);

        if (team_3_yes - rateMeter < 0.04) {
          team_3_yes = 0;
        } else {
          team_3_yes = team_3_yes - rateMeter;
        }
      }

      if (match_type === "Test") {
        if (
          payload.teamA_back_rate == betConverter(team_1_yes) &&
          payload.teamA_lay_rate == betConverter(team_1_no) &&
          payload.teamB_back_rate == betConverter(team_2_yes) &&
          payload.teamB_lay_rate == betConverter(team_2_no) &&
          payload.draw_back_rate == betConverter(team_3_yes) &&
          payload.draw_lay_rate == betConverter(team_3_no)
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.", {
            duration: 1000,
          });
          setMatchBetPayload({});
          setBetAllowed(false);
          setTimeLeft("");
          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }
          setPlaceBetLoader(false);
        }
      } else {
        if (
          payload.teamA_back_rate == betConverter(team_1_yes) &&
          payload.teamA_lay_rate == betConverter(team_1_no) &&
          payload.teamB_back_rate == betConverter(team_2_yes) &&
          payload.teamB_lay_rate == betConverter(team_2_no)
        ) {
          placeMatchBet(payload);
        } else {
          toast.error("Rate has changed.", {
            duration: 1000,
          });
          setMatchBetPayload({});
          setBetAllowed(false);
          setTimeLeft("");
          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }
          setPlaceBetLoader(false);
        }
      }
    }
  };

  const handlePlaceMatchBet = () => {
    const gameId = matchId;

    let matchMin = 0;
    let matchMax = 0;
    let betTimer = 0;

    getMatchSetting(gameId, {
      onSuccess: (data) => {
        matchMin = data.data.data.match_min;
        matchMax = data.data.data.match_max;
        betTimer = data.data.data.bet_timer;

        const payload = {
          amount: betAmount,
          ...matchBetPayload,
        };

        if (matchApi === "1") {
          if (betTimer === 0) {
            checkConditions(matchMin, matchMax, payload);
          } else {
            setTimeout(() => {
              checkConditions(matchMin, matchMax, payload);
            }, betTimer * 1000);
          }
        } else if (matchApi === "2") {
          if (betTimer === 0) {
            checkConditions2(matchMin, matchMax, payload);
          } else {
            setTimeout(() => {
              checkConditions2(matchMin, matchMax, payload);
            }, betTimer * 1000);
          }
        } else if (matchApi === "4") {
          if (betTimer === 0) {
            checkConditions3(matchMin, matchMax, payload);
          } else {
            setTimeout(() => {
              checkConditions3(matchMin, matchMax, payload);
            }, betTimer * 1000);
          }
        }
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const placeSessionBet = (payload) => {
    createSessionBet(payload, {
      onSuccess: (data) => {
        toast.success("Bet placed successfully!", {
          duration: 1000,
        });
        setSessionBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }

        getTeamMatchBets();
        handleGetSessionTotal();
        handleGetSessionMatchBets();
        setPlaceBetLoader(false);
      },
      onError: (error) => {
        console.log("error", error);
        setSessionBetPayload({});
        setBetAllowed(false);
        setTimeLeft("");
        const inputElement = document.querySelector("#MatchAmountInput");
        if (inputElement) {
          inputElement.value = "";
        }
        setPlaceBetLoader(false);
        toast.error("Amount should be less then your wallet balance.", {
          duration: 1000,
        });
      },
    });
  };

  const checkOperation = (matchMin, matchMax, payload) => {
    const marketId = payload.complete_session_id;

    if (matchCode === "0") {
      if (matchDummy.data.length === 5) {
        const market = matchDummy.data[2].markets;
        const res = market.filter((el) => el.id == marketId);

        if (res.length === 1) {
          if (payload.match_condn) {
            if (!res[0].runners[0].rnrBallRunning) {
              if (
                payload.runs == Number(res[0].runners[0].backRuns) &&
                payload.rate == Number(res[0].runners[0].backOdds) / 100
              ) {
                placeSessionBet(payload);
              } else {
                toast.error("Rate has changed.", {
                  duration: 1000,
                });
              }
            } else {
              toast.error("Session Bet is locked.", {
                duration: 1000,
              });
            }
          } else {
            if (!res[0].runners[0].rnrBallRunning) {
              if (
                payload.runs == Number(res[0].runners[0].layRuns) &&
                payload.rate == Number(res[0].runners[0].layOdds) / 100
              ) {
                placeSessionBet(payload);
              } else {
                toast.error("Rate has changed.", {
                  duration: 1000,
                });
              }
            } else {
              toast.error("Session Bet is locked.", {
                duration: 1000,
              });
            }
          }
        }
      }
    } else {
      let res = fancyOddsData.filter((el) => el.id == marketId);

      if (res.length === 1) {
        if (payload.match_condn) {
          if (!res[0].runners[0].rnrBallRunning) {
            if (
              payload.runs == Number(res[0].runners[0].backRuns) &&
              payload.rate == Number(res[0].runners[0].backOdds) / 100
            ) {
              placeSessionBet(payload);
            } else {
              toast.error("Rate has changed.", {
                duration: 1000,
              });
            }
          } else {
            toast.error("Session Bet is locked.", {
              duration: 1000,
            });
          }
        } else {
          if (!res[0].runners[0].rnrBallRunning) {
            if (
              payload.runs == Number(res[0].runners[0].layRuns) &&
              payload.rate == Number(res[0].runners[0].layOdds) / 100
            ) {
              placeSessionBet(payload);
            } else {
              toast.error("Rate has changed.", {
                duration: 1000,
              });
            }
          } else {
            toast.error("Session Bet is locked.", {
              duration: 1000,
            });
          }
        }
      } else {
        toast.error("Incorrect Bet", {
          duration: 1000,
        });
      }
    }
  };

  const checkOperation2 = (matchMin, matchMax, payload) => {
    const marketId = payload.complete_session_id;

    let res = fancyOddsData.filter((el) => el.id == marketId);

    if (res.length === 1) {
      if (payload.match_condn) {
        if (!res[0].runners[0].rnrBallRunning) {
          if (
            payload.runs == Number(res[0].runners[0].backRuns) &&
            payload.rate == Number(res[0].runners[0].backOdds) / 100
          ) {
            placeSessionBet(payload);
          } else {
            toast.error("Rate has changed.", {
              duration: 1000,
            });
          }
        } else {
          toast.error("Session Bet is locked.", {
            duration: 1000,
          });
        }
      } else {
        if (!res[0].runners[0].rnrBallRunning) {
          if (
            payload.runs == Number(res[0].runners[0].layRuns) &&
            payload.rate == Number(res[0].runners[0].layOdds) / 100
          ) {
            placeSessionBet(payload);
          } else {
            toast.error("Rate has changed.", {
              duration: 1000,
            });
          }
        } else {
          toast.error("Session Bet is locked.", {
            duration: 1000,
          });
        }
      }
    } else {
      toast.error("Incorrect Bet", {
        duration: 1000,
      });
    }
  };

  const checkOperation3 = (matchMin, matchMax, payload) => {
    const marketId = payload.complete_session_id;

    let res = fancyOddsData.filter((el) => el.id == marketId);

    if (res.length === 1) {
      if (payload.match_condn) {
        if (!res[0].runners[0].rnrBallRunning) {
          if (
            payload.runs == parseFloat(res[0].runners[0].backRuns) &&
            payload.rate == parseFloat(res[0].runners[0].backOdds) / 100
          ) {
            placeSessionBet(payload);
          } else {
            toast.error("Rate has changed.", {
              duration: 1000,
            });
            setMatchBetPayload({});
            setSessionBetPayload({});
            setBetAllowed(false);
            setTimeLeft("");
            const inputElement = document.querySelector("#MatchAmountInput");
            if (inputElement) {
              inputElement.value = "";
            }
            setPlaceBetLoader(false);
          }
        } else {
          toast.error("Session Bet is locked.", {
            duration: 1000,
          });
          setMatchBetPayload({});
          setSessionBetPayload({});
          setBetAllowed(false);
          setTimeLeft("");
          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }
          setPlaceBetLoader(false);
        }
      } else {
        if (!res[0].runners[0].rnrBallRunning) {
          if (
            payload.runs == Number(res[0].runners[0].layRuns) &&
            payload.rate == Number(res[0].runners[0].layOdds) / 100
          ) {
            placeSessionBet(payload);
          } else {
            toast.error("Rate has changed.", {
              duration: 1000,
            });
            setMatchBetPayload({});
            setSessionBetPayload({});
            setBetAllowed(false);
            setTimeLeft("");
            const inputElement = document.querySelector("#MatchAmountInput");
            if (inputElement) {
              inputElement.value = "";
            }
            setPlaceBetLoader(false);
          }
        } else {
          toast.error("Session Bet is locked.", {
            duration: 1000,
          });
          setMatchBetPayload({});
          setSessionBetPayload({});
          setBetAllowed(false);
          setTimeLeft("");
          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }
          setPlaceBetLoader(false);
        }
      }
    } else {
      toast.error("Incorrect Bet", {
        duration: 1000,
      });
    }
  };

  const handlePlaceSessionBet = () => {
    const gameId = matchId;

    let matchMin = 0;
    let matchMax = 0;
    let betTimer = 0;

    if (userId !== undefined) {
      const payload = {
        amount: betAmount,
        ...sessionBetPayload,
      };

      getMatchSetting(gameId, {
        onSuccess: (data) => {
          matchMin = data.data.data.session_min;
          matchMax = data.data.data.session_max;
          betTimer = data.data.data.bet_timer;

          if (payload.amount < matchMin) {
            setSessionBetPayload({});
            toast.error(`Incorrect Amount. Minimum bet amount is ${matchMin}`, {
              duration: 1000,
            });

            return;
          }

          if (payload.amount > matchMax) {
            setSessionBetPayload({});
            toast.error(`Incorrect Amount. Maximum bet amount is ${matchMax}`, {
              duration: 1000,
            });
            return;
          }

          if (matchApi === "1") {
            if (betTimer === 0) {
              checkOperation(matchMin, matchMax, payload);
            } else {
              setTimeout(() => {
                checkOperation(matchMin, matchMax, payload);
              }, betTimer * 1000);
            }
          } else if (matchApi === "2") {
            if (betTimer === 0) {
              checkOperation2(matchMin, matchMax, payload);
            } else {
              setTimeout(() => {
                checkOperation2(matchMin, matchMax, payload);
              }, betTimer * 1000);
            }
          } else if (matchApi === "4") {
            if (betTimer === 0) {
              checkOperation3(matchMin, matchMax, payload);
            } else {
              setTimeout(() => {
                checkOperation3(matchMin, matchMax, payload);
              }, betTimer * 1000);
            }
          }
        },
        onError: (error) => {
          console.log("error", error);
        },
      });
    }
  };

  const handleMatchBetTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current); // Clear the previous timer if it exists
    }

    setTimeLeft("7");

    intervalIdRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 0) {
          clearInterval(intervalIdRef.current);
          setBetAllowed(false);
          setTimeLeft("");

          setMatchBetPayload({});
          setSessionBetPayload({});
          setBetData([]);

          const inputElement = document.querySelector("#MatchAmountInput");
          if (inputElement) {
            inputElement.value = "";
          }

          setPlaceBetLoader(false);

          handleCloseDialog();

          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
  };

  const handleGetSessionTotal = () => {
    const sessionTotalPayload = {
      match_id: matchId,
      user_id: userId,
    };

    getSessionTotal(sessionTotalPayload, {
      onSuccess: (data) => {
        setSessionProfileLosses(data.data.data);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const handleOpenDialog = () => {
    setBetAmount("");
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setBetAmount("");
    setMatchBetPayload({});
    setSessionBetPayload({});
    setBetData([]);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    getTeamMatchBets();

    handleGetSessionMatchBets();

    handleGetSessionTotal();

    if (tvcodeapi === undefined) {
      setTvCodeUrl("https://supertv.lotusbook9mm.com/tv2/?event_id=" + tvcode);
    } else {
      if (tvcodeapi === "2") {
        setTvCodeUrl("https://100tun.online/web/" + tvcode + ".html");
      } else {
        setTvCodeUrl("https://vid1.live/live/play.html?id=" + tvcode);
      }
    }

    if (matchApi === "1") {
      setScoreBoardType("External");
    } else {
      setScoreBoardType("Betguru");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iceexchData) {
      setIceExchData(iceexchData.data);
      getGameDetails(iceexchData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iceexchData]);

  useEffect(() => {
    if (betguruData) {
      getGameDetails2(betguruData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betguruData]);

  useEffect(() => {
    if (personalapiData) {
      setPersonalData(personalapiData.data.data);
      getGameDetails4(personalapiData.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalapiData]);

  useEffect(() => {
    if (matchSettingsDataSocket) {
      handleMatchSessionSettingsData(matchSettingsDataSocket);
    }
  }, [matchSettingsDataSocket]);

  useEffect(() => {
    if (matchApi === "1") {
      if (matchCode !== "0") {
        handleGetGameDetailsPaid(matchCode);
      }
    } else if (matchApi === "2") {
      if (matchCode !== "0") {
        handleGetAppleExch(matchCode);
      }
    } else if (matchApi === "4") {
      if (matchCode !== "0") {
        handleGetGameDetailsPersonal(matchCode);
      }
    }

    if (matchApi === "1") {
      if (matchCode === "0") {
        getGameDetails(matchDummy.data);
      }
    } else {
      if (matchCode === "0") {
        getGameDetails3(match4);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchApi, matchCode]);

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ paddingBottom: "56px" }}
      >
        <div className="position-relative">
          <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <PageHeader />
          </div>
          {matchComment !== "" ? (
            <div className="welcomeMarquee">
              <marquee>{matchComment}</marquee>
            </div>
          ) : null}
          <div className="menu" id="menu" align="center">
            <ul className="nav">
              <li
                // style={{ backgroundColor: "#52B5E1 !important" }}
                className="active abc w-100"
              >
                <a
                  className="active text-center"
                  style={{
                    // backgroundColor: "#52B5E1 !important",
                    cursor: "pointer",
                  }}
                  id="tv_color_id1"
                  onClick={() => setShowText(!showText)}
                >
                  <FontAwesomeIcon icon={faTelevision} color="yellow" /> SHOW TV
                </a>
              </li>
              {showText && (
                <div className="w-100">
                  <iframe
                    src={tvCodeUrl}
                    title="Live Match"
                    class="match-tv"
                  ></iframe>
                </div>
              )}
            </ul>
            {/* {matchComment !== "" ? (
              <div className="welcomeMarquee">
                <marquee>{matchComment}</marquee>
              </div>
            ) : null} */}
          </div>

          {scoreBoardType === "Betguru" ? (
            <iframe
              title="demo"
              width="100%"
              src={`https://score.crickexpo.in/socket-iframe-3/${score_code}`}
              style={{ height: "160px" }}
            ></iframe>
          ) : (
            <iframe
              title="demo"
              width="100%"
              src={`https://score.crickexpo.in/socket-iframe-1/${score_code}`}
              style={{ height: "180px" }}
            ></iframe>
          )}

          {/* match Bhav */}
          <form name="BetPlayer">
            <div className="">
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td valign="top">
                      <table
                        width="100%"
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <tbody>
                          {/* Bhav Bet matchCode */}
                          <tr>
                            <td align="left" valign="top">
                              <table
                                width="100%"
                                border="0"
                                cellPadding="2"
                                cellSpacing="2"
                                className="table table-bordered align-middle mb-0 teamtable1"
                              >
                                <tbody>
                                  <tr bgcolor="#002d5b">
                                    <td
                                      width="60%"
                                      className="FontTextWhite10px"
                                    >
                                      TEAM
                                      <br />
                                      <span>
                                        Min : {matchMinAmt} | Max :{" "}
                                        {matchMaxAmt}
                                      </span>
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      LAGAI
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      KHAI
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody
                                  id="team-table-dst"
                                  bgcolor="#FFF"
                                  className="text-center"
                                >
                                  <tr>
                                    <td
                                      height="42"
                                      className="FontTextBlue"
                                      datatype="match"
                                    >
                                      {teamName1 === "" ? team1 : teamName1}
                                      <div
                                        className={`${
                                          team1Position > 0
                                            ? "text-primary"
                                            : "text-danger"
                                        } mt-1`}
                                      >
                                        {Math.round(team1Position)}
                                      </div>
                                    </td>
                                    <td
                                      className="text-black text-bold text-md"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#72bbef",
                                      }}
                                      onClick={(e) => {
                                        if (
                                          isGameLocked ||
                                          team1Yes === "0" ||
                                          team1Yes === "" ||
                                          team1Yes === 0
                                        ) {
                                          toast("No Data Found!", {
                                            icon: "👏",
                                            duration: 1000,
                                            style: {
                                              borderRadius: "10px",
                                              background: "#333",
                                              color: "#fff",
                                            },
                                          });
                                          return;
                                        }

                                        e.preventDefault();
                                        setMatchBetPayload({});
                                        setBetData([]);
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");

                                        const betDataPayload = [
                                          {
                                            betName:
                                              teamName1 === ""
                                                ? team1
                                                : teamName1,
                                            betType: "Lagai",
                                            rate: betConverter(team1Yes),
                                          },
                                        ];

                                        const payload = {
                                          type: "back",
                                          betTeam: "A",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                          draw_back_rate:
                                            betConverter(team3Yes),
                                          draw_lay_rate: betConverter(team3No),
                                          code: matchCode,
                                          api: matchApi,
                                          match_type: match_type,
                                        };
                                        setMatchBetPayload(payload);
                                        setBetData(betDataPayload);
                                        handleOpenDialog();
                                      }}
                                    >
                                      {isGameLocked ||
                                      team1Yes === "0" ||
                                      team1Yes === "" ||
                                      team1Yes === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team1Yes)
                                      )}
                                    </td>
                                    <td
                                      className="text-black text-bold text-md"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#faa9ba",
                                      }}
                                      onClick={(e) => {
                                        if (
                                          isGameLocked ||
                                          team1No === "0" ||
                                          team1No === "" ||
                                          team1No === 0
                                        ) {
                                          toast("No Data Found!", {
                                            icon: "👏",
                                            duration: 1000,
                                            style: {
                                              borderRadius: "10px",
                                              background: "#333",
                                              color: "#fff",
                                            },
                                          });
                                          return;
                                        }

                                        e.preventDefault();
                                        setMatchBetPayload({});
                                        setBetData([]);
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");

                                        const betDataPayload = [
                                          {
                                            betName:
                                              teamName1 === ""
                                                ? team1
                                                : teamName1,
                                            betType: "Khai",
                                            rate: betConverter(team1No),
                                          },
                                        ];

                                        const payload = {
                                          type: "lay",
                                          betTeam: "A",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                          draw_back_rate:
                                            betConverter(team3Yes),
                                          draw_lay_rate: betConverter(team3No),
                                          code: matchCode,
                                          api: matchApi,
                                          match_type: match_type,
                                        };
                                        setMatchBetPayload(payload);
                                        setBetData(betDataPayload);
                                        handleOpenDialog();
                                      }}
                                    >
                                      {isGameLocked ||
                                      team1No === "0" ||
                                      team1No === "" ||
                                      team1No === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team1No)
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      height="42"
                                      className="FontTextBlue"
                                      datatype="match"
                                    >
                                      {teamName2 === "" ? team2 : teamName2}
                                      <div
                                        className={`${
                                          team2Position > 0
                                            ? "text-primary"
                                            : "text-danger"
                                        } mt-1`}
                                      >
                                        {Math.round(team2Position)}
                                      </div>
                                    </td>
                                    <td
                                      className="text-black text-bold text-md"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#72bbef",
                                      }}
                                      onClick={(e) => {
                                        if (
                                          isGameLocked ||
                                          team2Yes === "0" ||
                                          team2Yes === "" ||
                                          team2Yes === 0
                                        ) {
                                          toast("No Data Found!", {
                                            icon: "👏",
                                            duration: 1000,
                                            style: {
                                              borderRadius: "10px",
                                              background: "#333",
                                              color: "#fff",
                                            },
                                          });
                                          return;
                                        }

                                        e.preventDefault();
                                        setMatchBetPayload({});
                                        setBetData([]);
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");

                                        const betDataPayload = [
                                          {
                                            betName:
                                              teamName2 === ""
                                                ? team2
                                                : teamName2,
                                            betType: "Lagai",
                                            rate: betConverter(team2Yes),
                                          },
                                        ];

                                        const payload = {
                                          type: "back",
                                          betTeam: "B",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                          draw_back_rate:
                                            betConverter(team3Yes),
                                          draw_lay_rate: betConverter(team3No),
                                          code: matchCode,
                                          api: matchApi,
                                          match_type: match_type,
                                        };
                                        setMatchBetPayload(payload);
                                        setBetData(betDataPayload);
                                        handleOpenDialog();
                                      }}
                                    >
                                      {isGameLocked ||
                                      team2Yes === "0" ||
                                      team2Yes === "" ||
                                      team2Yes === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team2Yes)
                                      )}
                                    </td>
                                    <td
                                      className="text-black text-bold text-md"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#faa9ba",
                                      }}
                                      onClick={(e) => {
                                        if (
                                          isGameLocked ||
                                          team2No === "0" ||
                                          team2No === "" ||
                                          team2No === 0
                                        ) {
                                          toast("No Data Found!", {
                                            icon: "👏",
                                            duration: 1000,
                                            style: {
                                              borderRadius: "10px",
                                              background: "#333",
                                              color: "#fff",
                                            },
                                          });
                                          return;
                                        }

                                        e.preventDefault();
                                        setMatchBetPayload({});
                                        setBetData([]);
                                        setBetAllowed(true);
                                        handleMatchBetTimer();
                                        setBetType("matchBet");

                                        const betDataPayload = [
                                          {
                                            betName:
                                              teamName2 === ""
                                                ? team2
                                                : teamName2,
                                            betType: "Khai",
                                            rate: betConverter(team2No),
                                          },
                                        ];

                                        const payload = {
                                          type: "lay",
                                          betTeam: "B",
                                          user_id: userId,
                                          match_id: matchId,
                                          teamA_back_rate:
                                            betConverter(team1Yes),
                                          teamA_lay_rate: betConverter(team1No),
                                          teamB_back_rate:
                                            betConverter(team2Yes),
                                          teamB_lay_rate: betConverter(team2No),
                                          draw_back_rate:
                                            betConverter(team3Yes),
                                          draw_lay_rate: betConverter(team3No),
                                          code: matchCode,
                                          api: matchApi,
                                          match_type: match_type,
                                        };
                                        setMatchBetPayload(payload);
                                        setBetData(betDataPayload);
                                        handleOpenDialog();
                                      }}
                                    >
                                      {isGameLocked ||
                                      team2No === "0" ||
                                      team2No === "" ||
                                      team2No === 0 ? (
                                        <span>0.00</span>
                                      ) : (
                                        betConverter(team2No)
                                      )}
                                    </td>
                                  </tr>
                                  {match_type === "Test" && (
                                    <tr>
                                      <td
                                        height="42"
                                        className="FontTextBlue"
                                        datatype="match"
                                      >
                                        {team3}
                                        <div
                                          className={`${
                                            team3Position > 0
                                              ? "text-primary"
                                              : "text-danger"
                                          } mt-1`}
                                        >
                                          {Math.round(team3Position)}
                                        </div>
                                      </td>
                                      <td
                                        className="text-black text-bold text-md"
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#72bbef",
                                        }}
                                        onClick={(e) => {
                                          if (
                                            isGameLocked ||
                                            team3Yes === "0" ||
                                            team3Yes === "" ||
                                            team3Yes === 0
                                          ) {
                                            toast("No Data Found!", {
                                              icon: "👏",
                                              duration: 2000,
                                              style: {
                                                borderRadius: "10px",
                                                background: "#333",
                                                color: "#fff",
                                              },
                                            });
                                            return;
                                          }

                                          e.preventDefault();
                                          setMatchBetPayload({});
                                          setBetData([]);
                                          setBetAllowed(true);
                                          handleMatchBetTimer();
                                          setBetType("matchBet");

                                          const betDataPayload = [
                                            {
                                              betName: team3,
                                              betType: "Lagai",
                                              rate: betConverter(team3Yes),
                                            },
                                          ];

                                          const payload = {
                                            type: "back",
                                            betTeam: "DRAW",
                                            user_id: userId,
                                            match_id: matchId,
                                            teamA_back_rate:
                                              betConverter(team1Yes),
                                            teamA_lay_rate:
                                              betConverter(team1No),
                                            teamB_back_rate:
                                              betConverter(team2Yes),
                                            teamB_lay_rate:
                                              betConverter(team2No),
                                            draw_back_rate:
                                              betConverter(team3Yes),
                                            draw_lay_rate:
                                              betConverter(team3No),
                                            code: matchCode,
                                            api: matchApi,
                                            match_type: match_type,
                                          };

                                          setMatchBetPayload(payload);
                                          setBetData(betDataPayload);
                                          handleOpenDialog();
                                        }}
                                      >
                                        {isGameLocked
                                          ? "0.00"
                                          : betConverter(team3Yes)}
                                      </td>
                                      <td
                                        className="text-black text-bold text-md"
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#faa9ba",
                                        }}
                                        onClick={(e) => {
                                          if (
                                            isGameLocked ||
                                            team3No === "0" ||
                                            team3No === "" ||
                                            team3No === 0
                                          ) {
                                            toast("No Data Found!", {
                                              icon: "👏",
                                              duration: 2000,
                                              style: {
                                                borderRadius: "10px",
                                                background: "#333",
                                                color: "#fff",
                                              },
                                            });
                                            return;
                                          }

                                          e.preventDefault();
                                          setMatchBetPayload({});
                                          setBetData([]);
                                          setBetAllowed(true);
                                          handleMatchBetTimer();
                                          setBetType("matchBet");

                                          const betDataPayload = [
                                            {
                                              betName: team3,
                                              betType: "Khai",
                                              rate: betConverter(team3No),
                                            },
                                          ];

                                          const payload = {
                                            type: "lay",
                                            betTeam: "DRAW",
                                            user_id: userId,
                                            match_id: matchId,
                                            teamA_back_rate:
                                              betConverter(team1Yes),
                                            teamA_lay_rate:
                                              betConverter(team1No),
                                            teamB_back_rate:
                                              betConverter(team2Yes),
                                            teamB_lay_rate:
                                              betConverter(team2No),
                                            draw_back_rate:
                                              betConverter(team3Yes),
                                            draw_lay_rate:
                                              betConverter(team3No),
                                            code: matchCode,
                                            api: matchApi,
                                            match_type: match_type,
                                          };

                                          setMatchBetPayload(payload);
                                          setBetData(betDataPayload);
                                          handleOpenDialog();
                                        }}
                                      >
                                        {isGameLocked
                                          ? "0.00"
                                          : betConverter(team3No)}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          {/* session bet */}
                          <tr>
                            <td align="left" valign="top">
                              <table
                                width="100%"
                                border="0"
                                cellPadding="2"
                                cellSpacing="2"
                                className="table table-bordered align-middle mb-0"
                              >
                                <tbody>
                                  <tr bgcolor="#ff911f">
                                    <td
                                      width="60%"
                                      height="44"
                                      className="FontTextWhite10px"
                                      id="sessionplusminuscolor"
                                    >
                                      SESSION PLUS/MINUS{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        <span
                                          className={`${
                                            sessionProfileLosses > 0
                                              ? "bg-success"
                                              : "bg-danger"
                                          } px-2 py-1 rounded-4`}
                                        >
                                          {sessionProfileLosses
                                            ? Math.round(sessionProfileLosses)
                                            : ""}
                                        </span>
                                      </span>
                                    </td>

                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      NO
                                    </td>
                                    <td
                                      width="20%"
                                      className="FontTextWhite10px"
                                    >
                                      YES
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody
                                  id="session-table-dst"
                                  bgcolor="#FFF"
                                  className="text-center align-middle"
                                >
                                  {fancyOddsData?.length !== 0 ? (
                                    fancyOddsData.map((item, index) => (
                                      <tr
                                        key={index}
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <td height="56" colSpan="1">
                                          {item.marketType}
                                          <br />
                                          <span
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "0.75rem",
                                              color: "#198754",
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            Min : {sessionMinAmt} | Max :{" "}
                                            {sessionMaxAmt}
                                          </span>

                                          {item.remark && item.remark !== "" ? (
                                            <div
                                              className="d-flex justify-content-center align-items-center text-white fw-normal"
                                              style={{
                                                margin: "-.5rem",
                                                marginTop: ".5rem",
                                                padding: ".2rem .5rem",
                                                background: "rgb(1, 102, 122)",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item?.remark}
                                            </div>
                                          ) : null}
                                        </td>

                                        <td
                                          className="text-black text-bold text-md"
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "#faa9ba",
                                          }}
                                          onClick={(e) => {
                                            if (
                                              item.runners[0].rnrBallRunning ||
                                              isGameLocked
                                            ) {
                                              alert("No Data!");
                                              return;
                                            }

                                            e.preventDefault();
                                            setSessionBetPayload({});
                                            setBetData([]);
                                            setBetAllowed(true);
                                            handleMatchBetTimer();
                                            setBetType("sessionBet");

                                            const betDataPayload = [
                                              {
                                                betName: item.marketType,
                                                betType:
                                                  "No - " +
                                                  item.runners[0].layRuns,
                                                rate: (
                                                  item.runners[0].layOdds / 100
                                                ).toFixed(2),
                                              },
                                            ];

                                            const payload = {
                                              unique:
                                                Number(
                                                  item.runners[0].backRuns
                                                ) >
                                                Number(item.runners[0].layRuns)
                                                  ? true
                                                  : false,
                                              user_id: userId,
                                              session: item.marketType,
                                              match_id: matchId,
                                              mode: "N",
                                              rate: Number(
                                                item.runners[0].layOdds / 100
                                              ),
                                              runs: Number(
                                                item.runners[0].layRuns
                                              ),
                                              match_condn: false,
                                              mob_commision: 0.0,
                                              code: matchCode,
                                              match_type: match_type,
                                              api: matchApi,
                                              trash: false,
                                              complete_session_id:
                                                matchApi === "1"
                                                  ? item.id
                                                  : item.runners[0].id,
                                            };
                                            setSessionBetPayload(payload);
                                            setBetData(betDataPayload);
                                            handleOpenDialog();
                                          }}
                                        >
                                          {item.runners[0].rnrBallRunning ||
                                          isGameLocked ? (
                                            <>
                                              <div className="pb-2">0</div>
                                              0.0
                                            </>
                                          ) : (
                                            <>
                                              <div className="pb-2">
                                                {item.runners[0].layRuns}
                                              </div>
                                              {(
                                                item.runners[0].layOdds / 100
                                              ).toFixed(2)}
                                            </>
                                          )}
                                        </td>
                                        <td
                                          className="text-black text-bold text-md"
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "#72bbef",
                                          }}
                                          onClick={(e) => {
                                            if (
                                              item.runners[0].rnrBallRunning ||
                                              isGameLocked
                                            ) {
                                              alert("No Data!");
                                              return;
                                            }

                                            e.preventDefault();
                                            setSessionBetPayload({});
                                            setBetAllowed(true);
                                            handleMatchBetTimer();
                                            setBetType("sessionBet");

                                            const betDataPayload = [
                                              {
                                                betName: item.marketType,
                                                betType:
                                                  "Yes - " +
                                                  item.runners[0].backRuns,
                                                rate: (
                                                  item.runners[0].backOdds / 100
                                                ).toFixed(2),
                                              },
                                            ];

                                            const payload = {
                                              unique:
                                                Number(
                                                  item.runners[0].backRuns
                                                ) >
                                                Number(item.runners[0].layRuns)
                                                  ? true
                                                  : false,
                                              user_id: userId,
                                              session: item.marketType,
                                              match_id: matchId,
                                              mode: "Y",
                                              rate: Number(
                                                item.runners[0].backOdds / 100
                                              ),
                                              runs: Number(
                                                item.runners[0].backRuns
                                              ),
                                              match_condn: true,
                                              mob_commision: 0.0,
                                              code: matchCode,
                                              match_type: match_type,
                                              api: matchApi,
                                              trash: false,
                                              complete_session_id:
                                                matchApi === "1"
                                                  ? item.id
                                                  : item.runners[0].id,
                                            };
                                            setSessionBetPayload(payload);
                                            setBetData(betDataPayload);
                                            handleOpenDialog();
                                          }}
                                        >
                                          {item.runners[0].rnrBallRunning ||
                                          isGameLocked ? (
                                            <>
                                              <div className="pb-2">0</div>
                                              0.0
                                            </>
                                          ) : (
                                            <>
                                              <div className="pb-2">
                                                {item.runners[0].backRuns}
                                              </div>
                                              {(
                                                item.runners[0].backOdds / 100
                                              ).toFixed(2)}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        className="text-center p-3 text-md text-bold bg-white"
                                      >
                                        No Session Available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

          {/* Match Bets */}
          <br />
          <div className="table-responsive">
            <table
              width="100%"
              border="0"
              cellPadding="2"
              cellSpacing="2"
              id="MyBets"
              className="table table-bordered align-middle"
            >
              <tbody>
                <tr bgcolor="#002d5b">
                  <td
                    height="35"
                    className="FontTextWhite10px"
                    style={{ width: "10px", borderWidth: "1px" }}
                  >
                    Sr.
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Rate
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Amount
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Mode
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ borderWidth: "1px" }}
                  >
                    Team
                  </td>
                </tr>
              </tbody>

              <tbody bgcolor="#FFF" className="text-center">
                {teamBets?.length !== 0 ? (
                  teamBets.map((item, index) => (
                    <tr key={index}>
                      <td
                        height="32"
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      >
                        {index + 1}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.rate}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.amount}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.mode}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.team}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-center p-2 text-md text-bold bg-white"
                    >
                      No Bet Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Session Bets */}
          <div>
            <table
              width="100%"
              border="0"
              cellPadding="2"
              cellSpacing="2"
              id="MyBets"
              className="table table-bordered align-middle"
            >
              <tbody>
                <tr bgcolor="#002d5b">
                  <td
                    height="35"
                    className="FontTextWhite10px sesstiontablerate"
                    id="tablelastcol1"
                    style={{ fontSize: "10px ", color: "black" }}
                  >
                    Sr.
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Session
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Rate
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Amount
                  </td>
                  <td
                    className="FontTextWhite10px"
                    style={{ fontSize: "10px " }}
                  >
                    Run
                  </td>
                  <td
                    className="FontTextWhite10px p1"
                    style={{ fontSize: "10px " }}
                  >
                    Mode
                  </td>
                  <td
                    className="FontTextWhite10px p2"
                    style={{ fontSize: "10px " }}
                  >
                    Dec
                  </td>
                </tr>
              </tbody>

              <tbody bgcolor="#FFF" className="text-center">
                {sessionBets?.length !== 0 ? (
                  sessionBets.map((item, index) => (
                    <tr key={index}>
                      <td
                        height="32"
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      >
                        {index + 1}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.session}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.rate.toFixed(2)}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.amount}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.runs}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.mode}
                      </td>
                      <td style={{ fontSize: "13px", fontWeight: "500" }}>
                        {item.declare}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="text-center p-2 text-md text-bold bg-white"
                    >
                      No Bet Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>

      <Dialog
        open={isDialogOpen}
        className="matchCreateBetDialog"
        onClose={handleCloseDialog}
      >
        <DialogTitle
          className="p-3 text-md fw-bold text-white"
          style={{
            backgroundColor: "#2d3e50",
          }}
        >
          <div className="justify-content-between align-items-center d-flex">
            PLACE BET
            <FontAwesomeIcon
              icon={faXmark}
              className="text-lg"
              onClick={handleCloseDialog}
              style={{ cursor: "pointer" }}
            />
          </div>
        </DialogTitle>
        <DialogContent className="p-0">
          <table className="table mb-0">
            <thead>
              <tr style={{ backgroundColor: "#d7d7d7" }}>
                <th style={{ width: "60%" }}>Bet</th>
                <th style={{ width: "20%" }}>Rate</th>
                <th style={{ width: "20%" }}>Profit</th>
              </tr>
            </thead>
            <tbody>
              {betData.length > 0 && (
                <tr className="align-middle">
                  <td>
                    {betData[0].betName}{" "}
                    <span className="fw-bold">{betData[0].betType}</span>
                  </td>
                  <td>{betData[0].rate}</td>
                  <td>{betAmount * betData[0].rate}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className="pt-2 px-2 d-flex align-items-center justify-content-between"
            style={{ gap: "8px" }}
          >
            <input
              type="number"
              name="MatchAmount"
              list="AmountList"
              id="MatchAmountInput"
              className="MatchAmount form-control text-md fw-semibold p-1 rounded-1"
              disabled={!betAllowed}
              onChange={(e) => {
                e.preventDefault();
                setBetAmount(e.target.value);
              }}
              min="0"
              maxLength="7"
              autoComplete="OFF"
              value={betAmount > 0 ? betAmount : ""}
              placeholder="Enter Amount"
              style={{ width: "90%", marginLeft: "0" }}
            />

            <input
              name="AmountTime"
              type="text"
              className="AmountTime p-1 rounded-1 bg-secondary"
              id="AmountTime"
              size="4"
              value={timeLeft}
              // value={7}
              maxLength="3"
              readOnly
              disabled
              autoComplete="OFF"
              style={{ width: "8%", marginLeft: "0" }}
            />
          </div>

          <div
            className="d-flex justify-content-center align-items-center p-2 flex-wrap"
            style={{ gap: "4px" }}
          >
            {amountData.map((item, index) => (
              <button
                key={index}
                className="btn btn-secondary m-0 rounded-1 align-items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setBetAmount(item);
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </DialogContent>
        <DialogActions style={{ gap: "8px", justifyContent: "center" }}>
          <button
            className="btn btn-danger m-0 rounded-1"
            onClick={handleCloseDialog}
            style={{ width: "50%" }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success m-0 rounded-1"
            type="submit"
            style={{ width: "50%" }}
            onClick={(e) => {
              e.preventDefault();
              if (!placeBetLoader) {
                setPlaceBetLoader(true);
                if (betType === "matchBet") {
                  handlePlaceMatchBet();
                } else {
                  handlePlaceSessionBet();
                }
              }
            }}
            disabled={placeBetLoader}
          >
            {placeBetLoader ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Place Bet"
            )}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
