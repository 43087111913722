import React, { useState, useEffect } from "react";
import "./UserInplay.css";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import useGetCompleteMatch from "./hooks/useGetCompleteMatch";
import { dateTimeFormatter } from "../utils/dateFormatter";

export default function UserCompletegamelist() {
  let userId = sessionStorage.getItem("UUID");

  const { mutate: getCompleteMatch } = useGetCompleteMatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };

      getCompleteMatch(payload, {
        onSuccess: (response) => {
          let matchData = response.data;

          let updatedData = [];

          if (matchData.sessionData.length !== 0) {
            matchData.sessionData.forEach((item) => {
              if (item.games === undefined) {
                updatedData.push(item.game); //changes Games
              } else {
                updatedData.push(item.games); //changes Games
              }
            });
          }

          if (matchData.betting.length !== 0) {
            matchData.betting.forEach((item) => {
              if (!updatedData.some((data) => data?.id === item.match_id)) {
                if (item.games === undefined) {
                  updatedData.push(item.game); //changes Games
                } else {
                  updatedData.push(item.games); //changes Games
                }
              }
            });
          }

          updatedData.sort((a, b) => {
            return new Date(b.server) - new Date(a.server);
          });

          setData(updatedData);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ overflowX: "hidden" }}
      >
        <div className="position-relative">
          <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <PageHeader title="IN PLAY" />
          </div>
          <div className="menu mt-2" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>
          <br />
          <div className="row px-0 pt-0" style={{ paddingBottom: "50px" }}>
            {data.length > 0 ? (
              data?.map((item, index) => (
                <div className="mb-3" key={index}>
                  <Link to={`/user-complete-game?id=${item?.id}`} state={item}>
                    <div
                      className="teamName row d-flex flex-column justify-content-center align-items-center py-2 text-black gap-2 text-center shadow-sm"
                      style={{ backgroundColor: "#002D5B" }}
                      // align="center"
                      id="completegameteamcolor"
                    >
                      {item?.team1} VS {item?.team2} ({item?.match_type})
                    </div>
                    <div
                      className="row d-flex flex-column justify-content-center align-items-center py-2 text-black gap-2 text-center shadow-sm"
                      style={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      <div id="teamnametext">
                        {dateTimeFormatter(item?.server)}
                      </div>
                      <div id="teamnametext">
                        Won by :{" "}
                        {item?.won_by === "A"
                          ? item?.team1
                          : item?.won_by === "B"
                          ? item?.team2
                          : "DRAW"}
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="mb-3">
                <div className="teamName shadow-sm">
                  No complete matches found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
