import axios from "axios";
import toast from "react-hot-toast";

function getToken() {
  const token = localStorage.getItem("token");
  const status = sessionStorage.getItem("status");

  if (status === null || !token) {
    console.log("hello");
    localStorage.clear();
    sessionStorage.clear();
    toast.error("Session expired, LOGIN AGAIN!!", {
      duration: 3000,
      style: {
        fontWeight: "bold",
      },
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
    throw new Error("Token not found");
  }

  return token;
}

function onRequest(config) {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["Cache-Control"] = "no-store";
  }
  return config;
}

function onResponseError(error) {
  if (error?.response?.status === 401) {
    // Global 401 error handling
    localStorage.clear();
    sessionStorage.clear();
    toast.error("Session expired, LOGIN AGAIN!!", {
      duration: 3000,
      style: {
        fontWeight: "bold",
      },
    });

    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }
  return Promise.reject(error);
}

export function createProtectedAxios() {
  const protectedAxios = axios.create();
  protectedAxios.interceptors.request.use(onRequest);
  protectedAxios.interceptors.response.use(
    (response) => response,
    onResponseError
  );

  return protectedAxios;
}
