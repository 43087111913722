import React from "react";
import { Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

export default function Footer() {
  let userId = sessionStorage.getItem("UUID");

  const { mutate: logoutUser } = useLogout();

  const logout = () => {
    const payload = {
      user_id: userId,
      acc_type: "user",
    };

    logoutUser(payload, {
      onSuccess: (response) => {
        console.log(response);
        toast.success("Logout Successfully");
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      <div
        className=""
        id="footergappage"
        style={{
          backgroundColor: "rgb(29, 29, 29)",
          height: "56px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",

          gap: "40px",
        }}
      >
        <Link to="/MainMenu">
          <FontAwesomeIcon
            icon={faHouse}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span align="center" style={{ color: "#ffffff", fontSize: "10px" }}>
              Home
            </span>
          </div>
        </Link>

        <Link to="/user-inplay">
          <FontAwesomeIcon
            icon={faPlay}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span align="center" style={{ color: "#ffffff", fontSize: "10px" }}>
              InPlay
            </span>
          </div>
        </Link>
        <Link to="/user-casino">
          <FontAwesomeIcon
            icon={faGamepad}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span align="center" style={{ color: "#ffffff", fontSize: "10px" }}>
              Casino
            </span>
          </div>
        </Link>
        <Link to="/user-ledger">
          <FontAwesomeIcon
            icon={faBook}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span align="center" style={{ color: "#ffffff", fontSize: "10px" }}>
              Ledger
            </span>
          </div>
        </Link>
        <Link
          onClick={() => {
            logout();
          }}
        >
          <FontAwesomeIcon
            icon={faRightFromBracket}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span align="center" style={{ color: "#ffffff", fontSize: "10px" }}>
              Logout
            </span>
          </div>
        </Link>
      </div>
    </>
  );
}
