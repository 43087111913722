// src/Sidebar.js
import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <h1 id="acelogosidebar" style={{ margin: "0", marginRight: "auto" }}>
          10XBAT
        </h1>

        <span className="close-btn" onClick={toggleSidebar}>
          X
        </span>
      </div>

      <ul align="center">
        <Link to="/MainMenu">
          <li id="sidebariconname">HOME</li>
        </Link>
        <Link to="/user-inplay">
          <li id="sidebariconname">IN PLAY</li>
        </Link>
        <Link to="/user-casino">
          <li id="sidebariconname">CASINO</li>
        </Link>
        <Link to="/user-ledger">
          <li id="sidebariconname">LEDGER</li>
        </Link>
        <Link to="/user-password">
          <li id="sidebariconname">PASSWORD</li>
        </Link>
        <Link to="/welcome">
          <li id="sidebariconname">RULES</li>
        </Link>
      </ul>
    </div>
  );
};

export default Sidebar;
