/* eslint-disable no-unused-vars */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default function LUCKY7RulesModal({ open, onClose }) {
  const BASE_URL = process.env.REACT_APP_CLOUDINARY_URL;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        className="p-3 text-md fw-bold text-white d-flex justify-content-between align-items-center"
        style={{
          backgroundColor: "#2d3e50",
        }}
      >
        <span>LUCKY 7 RULES</span>
        <span
          style={{ cursor: "pointer", fontSize: "20px", lineHeight: "25.6px" }}
        >
          <FontAwesomeIcon icon={faClose} onClick={onClose} />
        </span>
      </DialogTitle>
      <DialogContent className="p-0">
        <img src={`${BASE_URL}/lucky7Rules.jpg`} alt="Rules" width="768px" />
      </DialogContent>
    </Dialog>
  );
}
