import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

const BASE_URL = process.env.REACT_APP_LUCKY_API_URL;

async function mutationFn() {
  const axios = createProtectedAxios();

  const endpoint = `${BASE_URL}/last-10-results?casinoGames=DRAGON_TIGER_20`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetDragonTigerResult() {
  const { mutate, isLoading, data } = useMutation(
    ["get-dragon-tiger-result"],
    () => mutationFn(),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
