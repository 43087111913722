/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
// import { toast } from "react-toastify";
import { dateTimeFormatter } from "../utils/dateFormatter";
import "./UserPage.css";

export default function UserProfile() {
  let userData = JSON.parse(sessionStorage.getItem("DATA"));
  const [data, setData] = useState([]);

  useEffect(() => {
    if (userData) {
      setData(userData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
        <PageHeader />
      </div>
      <div className="menu mt-2" id="menu" align="center">
        <ul className="nav">
          <li className="active abc w-100">
            <Link to="/MainMenu" style={{ height: "32.1px" }}>
              BACK TO MAIN MENU
            </Link>
          </li>
        </ul>
      </div>
      <table
        width="100%"
        style={{ background: "#e9e9e9", borderCollapse: "separate" }}
        border="0"
        cellspacing="0"
        cellpadding="0"
      >
        <tbody>
          <tr>
            <td valign="top">
              <table
                width="100%"
                style={{ background: "#e9e9e9", borderCollapse: "separate" }}
                border="0"
                cellpadding="0"
                cellspacing="0"
              >
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        style={{
                          background: "#e9e9e9",
                          borderCollapse: "separate",
                        }}
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              height="35"
                              align="center"
                              bgcolor="#888399"
                              className="TeamCombo"
                            >
                              <p className="style13">RATE INFORMATION </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <div id="msgCon" align="center" bgcolor="#f1f1f1"></div>
                      <table
                        width="100%"
                        style={{
                          background: "#e9e9e9",
                          borderCollapse: "separate",
                        }}
                        border="0"
                        cellpadding="2"
                        cellspacing="2"
                      >
                        <tbody>
                          <tr>
                            <td
                              height="35"
                              width="33%"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              Rate Difference :
                            </td>
                            <td
                              align="center"
                              width="33%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style14"
                            >
                              <select
                                name="Rate1"
                                id="Rate1"
                                defaultValue={
                                  localStorage.getItem("Rate1") || "0.00"
                                }
                                onChange={(e) =>
                                  localStorage.setItem("Rate1", e.target.value)
                                }
                              >
                                <option value="0.00">0</option>{" "}
                                <option value="0.01">1</option>{" "}
                                <option value="0.02">2</option>
                                <option value="0.03">3</option>{" "}
                                <option value="0.04">4</option>{" "}
                                <option value="0.05">5</option>
                              </select>
                            </td>
                            <td
                              align="center"
                              width="33%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style1"
                            >
                              <div className="menu" id="menu" align="center">
                                <ul
                                  className="nav"
                                  style={{
                                    backgroundColor: "#87bb33",
                                    border: " 1px solid #000",
                                  }}
                                >
                                  <li className="activeRate">
                                    <a
                                      className="btnClk updatebuttonhover"
                                      style={{ color: "white" }}
                                    >
                                      UPDATE
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" valign="bottom" bgcolor="#FFFFFF"></td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <table
                width="100%"
                style={{ background: "#e9e9e9", borderCollapse: "separate" }}
                border="0"
                cellpadding="0"
                cellspacing="0"
              >
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        style={{
                          background: "#e9e9e9",
                          borderCollapse: "separate",
                        }}
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              height="35"
                              align="center"
                              bgcolor="#888399"
                              className="TeamCombo"
                            >
                              <p className="style15">PERSONAL INFORMATION</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        style={{
                          background: "#e9e9e9",
                          borderCollapse: "separate",
                        }}
                        border="0"
                        cellpadding="2"
                        cellspacing="2"
                      >
                        <tbody>
                          <tr>
                            <td
                              height="35"
                              width="50%"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              Client Code :
                            </td>
                            <td
                              align="center"
                              width="50%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              {data ? data.code : "Code"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height="35"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              Client Name :
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              {data ? data.name : "Name"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height="35"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              Date Of Joining :
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              {data
                                ? dateTimeFormatter(data.createdAt)
                                : "DD/MM/YYYY"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height="38"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              Phone :
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              {data ? data.mobile : "Phone"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height="38"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              Address :
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              INDIA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" valign="bottom" bgcolor="#FFFFFF"></td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <table
                width="100%"
                style={{ background: "#e9e9e9", borderCollapse: "separate" }}
                border="0"
                cellpadding="0"
                cellspacing="0"
              >
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        style={{
                          background: "#e9e9e9",
                          borderCollapse: "separate",
                        }}
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              height="35"
                              align="center"
                              bgcolor="#888399"
                              className="TeamCombo"
                            >
                              <p className="style16">COMPANY INFORMATION </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        style={{
                          background: "#e9e9e9",
                          borderCollapse: "separate",
                        }}
                        border="0"
                        cellpadding="2"
                        cellspacing="2"
                      >
                        <tbody>
                          <tr>
                            <td
                              height="35"
                              width="50%"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              HELP LINE NO :
                            </td>
                            <td
                              align="center"
                              width="50%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue style12"
                            >
                              +91-1234567890
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td align="left" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
