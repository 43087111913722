export const dateTimeFormatter = (date) => {
  const format = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  return new Date(date).toLocaleString("en-US", format).replace(" at", ",");
};

export const dateFormatter = (date) => {
  const format = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    timeZone: "Asia/Kolkata",
  };

  return new Date(date).toLocaleString("en-US", format);
};

export const timeFormatter = (date) => {
  const format = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  return new Date(date).toLocaleString("en-US", format);
};

export const convertToUTC = (dateTimeString) => {
  // Create a Date object assuming the dateTimeString is in "Asia/Kolkata" (IST)
  const istDate = new Date(dateTimeString.replace(" ", "T") + "+05:30");

  // Convert the IST date to UTC using toISOString()
  const utcDate = istDate.toISOString();

  return utcDate;
};
