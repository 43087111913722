export const match = {
  status: "OK",
  code: 200,
  data: [
    {
      markets: [
        {
          id: 587717,
          marketId: "587717",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "Bookmaker 0%",
          minStake: 0.1,
          maxStake: 6000.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Bookmaker_Market",
          locIndex: 1,
          runners: [
            {
              selectionId: 1047665,
              sortPriority: 0,
              runnerName: "Dubai Capitals",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              playerExposure: "0",
              backOdds: 63.0,
              layOdds: 65.0,
              layStatus: "ACTIVE",
              marketId: "587717",
              runs: 0.0,
              backOddsList: ["63.0", "-", "-"],
              backVolList: ["1000", "-", "-"],
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047665,
              layOddsList: ["65.0", "-", "-"],
              layVolList: ["1000", "-", "-"],
            },
            {
              selectionId: 1047668,
              sortPriority: 1,
              runnerName: "Gulf Giants",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              playerExposure: "0",
              backOdds: 98.0,
              layOdds: 99.0,
              layStatus: "ACTIVE",
              marketId: "587717",
              runs: 0.0,
              backOddsList: ["100.0", "-", "-"],
              backVolList: ["1000", "-", "-"],
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047668,
              layOddsList: ["101.0", "-", "-"],
              layVolList: ["1000", "-", "-"],
            },
            {
              selectionId: 104766969,
              sortPriority: 1,
              runnerName: "DRAW",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              playerExposure: "0",
              backOdds: 50.0,
              layOdds: 51.0,
              layStatus: "ACTIVE",
              marketId: "587717",
              runs: 0.0,
              backOddsList: ["50.0", "-", "-"],
              backVolList: ["1000", "-", "-"],
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 104766969,
              layOddsList: ["51.0", "-", "-"],
              layVolList: ["1000", "-", "-"],
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
      ],
      categoryDetails: {
        id: 3,
        catName: "Bookmaker 0% comm.",
        oddTypeCat: "Back/Lay",
        oddType: "AM",
        runnersNo: "MULTIPLE",
        update9wktFancy: false,
        fancyIcon: false,
        bookMakerIcon: false,
        tvIcon: false,
      },
    },
    {
      markets: [
        {
          id: 587719,
          marketId: "587719",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "Bookmaker 0.5%",
          minStake: 0.1,
          maxStake: 3000.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Bookmaker_Disc",
          locIndex: 1,
          runners: [
            {
              selectionId: 1047667,
              sortPriority: 0,
              runnerName: "Dubai Capitals",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              playerExposure: "0",
              backOdds: 63.0,
              layOdds: 65.0,
              layStatus: "ACTIVE",
              marketId: "587719",
              runs: 0.0,
              backOddsList: ["63.0", "-", "-"],
              backVolList: ["1000", "-", "-"],
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047667,
              layOddsList: ["65.0", "-", "-"],
              layVolList: ["1000", "-", "-"],
            },
            {
              selectionId: 1047670,
              sortPriority: 1,
              runnerName: "Gulf Giants",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              playerExposure: "0",
              backOdds: 100.0,
              layOdds: 101.0,
              layStatus: "ACTIVE",
              marketId: "587719",
              runs: 0.0,
              backOddsList: ["100.0", "-", "-"],
              backVolList: ["1000", "-", "-"],
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047670,
              layOddsList: ["101.0", "-", "-"],
              layVolList: ["1000", "-", "-"],
            },
          ],
          comment: "",
          marketDisc: 0.5,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
      ],
      categoryDetails: {
        id: 5,
        catName: "Bookmaker Market",
        oddTypeCat: "Back/Lay",
        oddType: "AM",
        runnersNo: "MULTIPLE",
        update9wktFancy: false,
        fancyIcon: false,
        bookMakerIcon: false,
        tvIcon: false,
      },
    },
    {
      markets: [
        {
          id: 588123,
          marketId: "588123",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "4 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 4,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1048405,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 120.0,
              layOdds: 90.0,
              layStatus: "ACTIVE",
              marketId: "588123",
              runs: 31.0,
              backRuns: 32.0,
              layRuns: 35.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1048405,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588132,
          marketId: "588132",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "5 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 5,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588126,
          marketId: "588126",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "7 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 7,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588128,
          marketId: "588128",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "8 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 8,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588129,
          marketId: "588129",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "9 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 9,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588133,
          marketId: "588133",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "11 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 11,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588135,
          marketId: "588135",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "12 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 12,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588137,
          marketId: "588137",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "13 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 13,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588143,
          marketId: "588143",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "14 Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 14,
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588142,
          marketId: "588142",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "Only 5th Over Run DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 25,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1048283,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "588142",
              runs: 8.0,
              backRuns: 8.0,
              layRuns: 8.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1048283,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587725,
          marketId: "587725",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "6 Over Run DC Adv",
          minStake: 0.15,
          maxStake: 800.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 101,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047712,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 90.0,
              layOdds: 110.0,
              layStatus: "ACTIVE",
              marketId: "587725",
              runs: 48.0,
              backRuns: 36.0,
              layRuns: 36.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047712,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587727,
          marketId: "587727",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "20 Over Run DC Adv",
          minStake: 0.15,
          maxStake: 400.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 200,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047714,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587727",
              runs: 171.0,
              backRuns: 173.0,
              layRuns: 175.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047714,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587729,
          marketId: "587729",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "Fall Of 1st Wkt DC Adv",
          minStake: 0.15,
          maxStake: 400.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 250,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047716,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587729",
              runs: 45.0,
              backRuns: 40.0,
              layRuns: 38.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047716,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587731,
          marketId: "587731",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "1st 3 Wkt Run DC Adv",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 251,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047718,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 110.0,
              layStatus: "ACTIVE",
              marketId: "587731",
              runs: 102.0,
              backRuns: 102.0,
              layRuns: 102.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047718,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587733,
          marketId: "587733",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "J Root Run Adv",
          minStake: 0.15,
          maxStake: 400.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 350,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047720,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 90.0,
              layOdds: 200.0,
              layStatus: "ACTIVE",
              marketId: "587733",
              runs: 28.0,
              backRuns: 28.0,
              layRuns: 28.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047720,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587734,
          marketId: "587734",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "R Uthappa Run Adv",
          minStake: 0.15,
          maxStake: 400.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 351,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047721,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 90.0,
              layOdds: 110.0,
              layStatus: "ACTIVE",
              marketId: "587734",
              runs: 37.0,
              backRuns: 37.0,
              layRuns: 37.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047721,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588139,
          marketId: "588139",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "J Root Boundaries",
          minStake: 0.15,
          maxStake: 100.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 450,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1048280,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "588139",
              runs: 3.0,
              backRuns: 4.0,
              layRuns: 3.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1048280,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588136,
          marketId: "588136",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "R Uthappa Boundaries",
          minStake: 0.15,
          maxStake: 100.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 451,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1048281,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 85.0,
              layOdds: 115.0,
              layStatus: "ACTIVE",
              marketId: "588136",
              runs: 6.0,
              backRuns: 6.0,
              layRuns: 6.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1048281,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588120,
          marketId: "588120",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "1st Wkt Pship Boundaries DC",
          minStake: 0.15,
          maxStake: 100.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 500,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1048273,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "588120",
              runs: 6.0,
              backRuns: 7.0,
              layRuns: 6.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1048273,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 588127,
          marketId: "588127",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "1st Wkt Caught Out DC",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Fancy_Market",
          locIndex: 501,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1048276,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 35.0,
              layOdds: 50.0,
              layStatus: "ACTIVE",
              marketId: "588127",
              runs: 1.0,
              backRuns: 1.0,
              layRuns: 1.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: false,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1048276,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
      ],
      categoryDetails: {
        id: 6,
        catName: "Fancy Market",
        oddTypeCat: "Yes/No",
        oddType: "AM",
        runnersNo: "SINGLE",
        update9wktFancy: false,
        fancyIcon: false,
        bookMakerIcon: false,
        tvIcon: false,
      },
    },
    {
      markets: [
        {
          id: 587749,
          marketId: "587749",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "D Lawrence",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 1,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047736,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587749",
              runs: 31.0,
              backRuns: 33.0,
              layRuns: 31.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047736,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587750,
          marketId: "587750",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "F Allen",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 2,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047737,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587750",
              runs: 29.0,
              backRuns: 31.0,
              layRuns: 29.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047737,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587751,
          marketId: "587751",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "R Powell",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 3,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047738,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587751",
              runs: 26.0,
              backRuns: 30.0,
              layRuns: 28.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047738,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587752,
          marketId: "587752",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "R Uthappa",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 4,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047739,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587752",
              runs: 34.0,
              backRuns: 36.0,
              layRuns: 34.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047739,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587753,
          marketId: "587753",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "Sikandar Raza",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 5,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047740,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587753",
              runs: 34.0,
              backRuns: 38.0,
              layRuns: 36.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047740,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587754,
          marketId: "587754",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "J Vince",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 6,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047741,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587754",
              runs: 28.0,
              backRuns: 30.0,
              layRuns: 28.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047741,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587755,
          marketId: "587755",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "L Dawson",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 7,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047742,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587755",
              runs: 28.0,
              backRuns: 30.0,
              layRuns: 28.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047742,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587756,
          marketId: "587756",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "O Pope",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 8,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047743,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587756",
              runs: 32.0,
              backRuns: 34.0,
              layRuns: 32.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047743,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587757,
          marketId: "587757",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "Q Ahmad",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 9,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047744,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587757",
              runs: 24.0,
              backRuns: 26.0,
              layRuns: 24.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047744,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587758,
          marketId: "587758",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "S Hetmyer",
          minStake: 0.1,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "PP_Market",
          locIndex: 10,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047745,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 100.0,
              layOdds: 100.0,
              layStatus: "ACTIVE",
              marketId: "587758",
              runs: 22.0,
              backRuns: 24.0,
              layRuns: 22.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047745,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
      ],
      categoryDetails: {
        id: 17,
        catName: "Player Performance Market",
        oddTypeCat: "Yes/No",
        oddType: "AM",
        runnersNo: "SINGLE",
        update9wktFancy: false,
        fancyIcon: false,
        bookMakerIcon: false,
        tvIcon: false,
      },
    },
    {
      markets: [
        {
          id: 587720,
          marketId: "587720",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "6 Over Last Digit",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Lucky_Num_Market",
          locIndex: 1,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047676,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 0.0,
              backRuns: 0.0,
              layRuns: 0.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047676,
            },
            {
              selectionId: 1047675,
              sortPriority: 2,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 1.0,
              backRuns: 1.0,
              layRuns: 1.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047675,
            },
            {
              selectionId: 1047677,
              sortPriority: 3,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 2.0,
              backRuns: 2.0,
              layRuns: 2.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047677,
            },
            {
              selectionId: 1047671,
              sortPriority: 4,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 3.0,
              backRuns: 3.0,
              layRuns: 3.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047671,
            },
            {
              selectionId: 1047674,
              sortPriority: 5,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 4.0,
              backRuns: 4.0,
              layRuns: 4.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047674,
            },
            {
              selectionId: 1047673,
              sortPriority: 6,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 5.0,
              backRuns: 5.0,
              layRuns: 5.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047673,
            },
            {
              selectionId: 1047672,
              sortPriority: 7,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 6.0,
              backRuns: 6.0,
              layRuns: 6.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047672,
            },
            {
              selectionId: 1047680,
              sortPriority: 8,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 7.0,
              backRuns: 7.0,
              layRuns: 7.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047680,
            },
            {
              selectionId: 1047679,
              sortPriority: 9,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 8.0,
              backRuns: 8.0,
              layRuns: 8.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047679,
            },
            {
              selectionId: 1047678,
              sortPriority: 10,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587720",
              runs: 9.0,
              backRuns: 9.0,
              layRuns: 9.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047678,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587721,
          marketId: "587721",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "10 Over Last Digit",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Lucky_Num_Market",
          locIndex: 2,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047684,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 0.0,
              backRuns: 0.0,
              layRuns: 0.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047684,
            },
            {
              selectionId: 1047686,
              sortPriority: 2,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 1.0,
              backRuns: 1.0,
              layRuns: 1.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047686,
            },
            {
              selectionId: 1047688,
              sortPriority: 3,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 2.0,
              backRuns: 2.0,
              layRuns: 2.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047688,
            },
            {
              selectionId: 1047687,
              sortPriority: 4,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 3.0,
              backRuns: 3.0,
              layRuns: 3.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047687,
            },
            {
              selectionId: 1047681,
              sortPriority: 5,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 4.0,
              backRuns: 4.0,
              layRuns: 4.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047681,
            },
            {
              selectionId: 1047682,
              sortPriority: 6,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 5.0,
              backRuns: 5.0,
              layRuns: 5.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047682,
            },
            {
              selectionId: 1047690,
              sortPriority: 7,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 6.0,
              backRuns: 6.0,
              layRuns: 6.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047690,
            },
            {
              selectionId: 1047685,
              sortPriority: 8,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 7.0,
              backRuns: 7.0,
              layRuns: 7.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047685,
            },
            {
              selectionId: 1047689,
              sortPriority: 9,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 8.0,
              backRuns: 8.0,
              layRuns: 8.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047689,
            },
            {
              selectionId: 1047683,
              sortPriority: 10,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587721",
              runs: 9.0,
              backRuns: 9.0,
              layRuns: 9.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047683,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587722,
          marketId: "587722",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "15 Over Last Digit",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Lucky_Num_Market",
          locIndex: 3,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047692,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 0.0,
              backRuns: 0.0,
              layRuns: 0.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047692,
            },
            {
              selectionId: 1047695,
              sortPriority: 2,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 1.0,
              backRuns: 1.0,
              layRuns: 1.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047695,
            },
            {
              selectionId: 1047697,
              sortPriority: 3,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 2.0,
              backRuns: 2.0,
              layRuns: 2.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047697,
            },
            {
              selectionId: 1047693,
              sortPriority: 4,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 3.0,
              backRuns: 3.0,
              layRuns: 3.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047693,
            },
            {
              selectionId: 1047700,
              sortPriority: 5,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 4.0,
              backRuns: 4.0,
              layRuns: 4.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047700,
            },
            {
              selectionId: 1047691,
              sortPriority: 6,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 5.0,
              backRuns: 5.0,
              layRuns: 5.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047691,
            },
            {
              selectionId: 1047699,
              sortPriority: 7,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 6.0,
              backRuns: 6.0,
              layRuns: 6.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047699,
            },
            {
              selectionId: 1047698,
              sortPriority: 8,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 7.0,
              backRuns: 7.0,
              layRuns: 7.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047698,
            },
            {
              selectionId: 1047696,
              sortPriority: 9,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 8.0,
              backRuns: 8.0,
              layRuns: 8.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047696,
            },
            {
              selectionId: 1047694,
              sortPriority: 10,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587722",
              runs: 9.0,
              backRuns: 9.0,
              layRuns: 9.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047694,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
        {
          id: 587723,
          marketId: "587723",
          eventId: "32031823",
          eventName: "Dubai Capitals v Gulf Giants",
          marketType: "20 Over Last Digit",
          minStake: 0.15,
          maxStake: 200.0,
          fancy: true,
          status: "OPEN",
          ballRunning: false,
          fancyCategory: "Lucky_Num_Market",
          locIndex: 4,
          playerExposure: "0",
          runners: [
            {
              selectionId: 1047704,
              sortPriority: 1,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 0.0,
              backRuns: 0.0,
              layRuns: 0.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047704,
            },
            {
              selectionId: 1047707,
              sortPriority: 2,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 1.0,
              backRuns: 1.0,
              layRuns: 1.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047707,
            },
            {
              selectionId: 1047703,
              sortPriority: 3,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 2.0,
              backRuns: 2.0,
              layRuns: 2.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047703,
            },
            {
              selectionId: 1047706,
              sortPriority: 4,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 3.0,
              backRuns: 3.0,
              layRuns: 3.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047706,
            },
            {
              selectionId: 1047705,
              sortPriority: 5,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 4.0,
              backRuns: 4.0,
              layRuns: 4.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047705,
            },
            {
              selectionId: 1047708,
              sortPriority: 6,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 5.0,
              backRuns: 5.0,
              layRuns: 5.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047708,
            },
            {
              selectionId: 1047709,
              sortPriority: 7,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 6.0,
              backRuns: 6.0,
              layRuns: 6.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047709,
            },
            {
              selectionId: 1047701,
              sortPriority: 8,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 7.0,
              backRuns: 7.0,
              layRuns: 7.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047701,
            },
            {
              selectionId: 1047710,
              sortPriority: 9,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 8.0,
              backRuns: 8.0,
              layRuns: 8.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047710,
            },
            {
              selectionId: 1047702,
              sortPriority: 10,
              runnerName: "",
              rnrStatus: "ACTIVE",
              rnrVisibleToPl: true,
              backOdds: 850.0,
              layOdds: 0.0,
              layStatus: "ACTIVE",
              marketId: "587723",
              runs: 9.0,
              backRuns: 9.0,
              layRuns: 9.0,
              backBallRunning: false,
              backRnrSuspended: false,
              rnrBallRunning: true,
              rnrSuspended: false,
              layBallRunning: false,
              layRnrSuspended: false,
              id: 1047702,
            },
          ],
          comment: "",
          marketDisc: 0.0,
          update9wktFancy: false,
          fancyIcon: false,
          bookMakerIcon: false,
          tvIcon: false,
        },
      ],
      categoryDetails: {
        id: 19,
        catName: "Lucky Number Market",
        oddTypeCat: "Yes/No",
        oddType: "AM",
        runnersNo: "SINGLE",
        update9wktFancy: false,
        fancyIcon: false,
        bookMakerIcon: false,
        tvIcon: false,
      },
    },
  ],
  timestamp: "16/01/2023 14:15:32",
};

export const match4 = {
  market: [
    {
      marketId: "1.215397099",
      inplay: "true",
      totalMatched: "",
      totalAvailable: "",
      priceStatus: "ACTIVE",
      events: [
        {
          SelectionId: "1",
          RunnerName: "England",
          LayPrice1: "0",
          LaySize1: "97.34",
          LayPrice2: "0.0",
          LaySize2: "$ 0",
          LayPrice3: "0.0",
          LaySize3: "$ 0",
          BackPrice1: "4",
          BackSize1: "2743.72",
          BackPrice2: "0.0",
          BackSize2: "$ 0",
          BackPrice3: "0.0",
          BackSize3: "$ 0",
          Status: "SUSPENDED",
        },
        {
          SelectionId: "2",
          RunnerName: "Australia",
          LayPrice1: "1.3",
          LaySize1: "2263.46",
          LayPrice2: "0.0",
          LaySize2: "$ 0",
          LayPrice3: "0.0",
          LaySize3: "$ 0",
          BackPrice1: "1.28",
          BackSize1: "50651.38",
          BackPrice2: "0.0",
          BackSize2: "$ 0",
          BackPrice3: "0.0",
          BackSize3: "$ 0",
          Status: "ACTIVE",
        },
        {
          SelectionId: "3",
          RunnerName: "The Draw",
          LayPrice1: "0",
          LaySize1: "8",
          LayPrice2: "0.0",
          LaySize2: "$ 0",
          LayPrice3: "0.0",
          LaySize3: "$ 0",
          BackPrice1: "0",
          BackSize1: "3.56",
          BackPrice2: "0.0",
          BackSize2: "$ 0",
          BackPrice3: "0.0",
          BackSize3: "$ 0",
          Status: "SUSPENDED",
        },
      ],
    },
  ],
  session: [
    {
      SelectionId: "fall_of_6th_wkt_eng_2",
      RunnerName: "Fall of 6th wkt ENG 2",
      LayPrice1: "213",
      LaySize1: "100",
      BackPrice1: "213",
      BackSize1: "80",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 200000,
    },
    {
      SelectionId: "6th_wkt_pship_boundaries_eng_2",
      RunnerName: "6th wkt pship boundaries ENG 2",
      LayPrice1: "0",
      LaySize1: "0",
      BackPrice1: "0",
      BackSize1: "0",
      GameStatus: "SUSPENDED",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 100000,
    },
    {
      SelectionId: "b_stokes_run_2",
      RunnerName: "B Stokes run 2",
      LayPrice1: "85",
      LaySize1: "100",
      BackPrice1: "85",
      BackSize1: "80",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 200000,
    },
    {
      SelectionId: "b_stokes_boundaries_2",
      RunnerName: "B Stokes boundaries 2",
      LayPrice1: "8",
      LaySize1: "100",
      BackPrice1: "9",
      BackSize1: "100",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 100000,
    },
    {
      SelectionId: "j_bairstow_run_2",
      RunnerName: "J Bairstow run 2",
      LayPrice1: "30",
      LaySize1: "110",
      BackPrice1: "30",
      BackSize1: "90",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 200000,
    },
    {
      SelectionId: "j_bairstow_boundaries_2",
      RunnerName: "J Bairstow boundaries 2",
      LayPrice1: "0",
      LaySize1: "0",
      BackPrice1: "0",
      BackSize1: "0",
      GameStatus: "SUSPENDED",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 100000,
    },
    {
      SelectionId: "fall_of_6th_wkt_run_bhav_eng_2",
      RunnerName: "Fall of 6th wkt run bhav ENG 2",
      LayPrice1: "186",
      LaySize1: "5",
      BackPrice1: "186",
      BackSize1: "2",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 500000,
    },
    {
      SelectionId: "b_stokes_run_bhav_2",
      RunnerName: "B Stokes run bhav 2",
      LayPrice1: "59",
      LaySize1: "4",
      BackPrice1: "59",
      BackSize1: "2",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 500000,
    },
    {
      SelectionId: "j_bairstow_run_bhav_2",
      RunnerName: "J Bairstow run bhav 2",
      LayPrice1: "7",
      LaySize1: "5",
      BackPrice1: "7",
      BackSize1: "2",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 500000,
    },
    {
      SelectionId: "48_over_run_eng_2",
      RunnerName: "48 over run ENG 2.",
      LayPrice1: "186",
      LaySize1: "130",
      BackPrice1: "186",
      BackSize1: "80",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 200000,
    },
    {
      SelectionId: "483_over_run_eng_2",
      RunnerName: "48.3 over run ENG 2.",
      LayPrice1: "187",
      LaySize1: "100",
      BackPrice1: "188",
      BackSize1: "100",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 200000,
    },
    {
      SelectionId: "50_over_run_eng_2",
      RunnerName: "50 over run ENG 2.",
      LayPrice1: "193",
      LaySize1: "100",
      BackPrice1: "194",
      BackSize1: "100",
      GameStatus: "",
      FinalStatus: null,
      isActive: false,
      min: 100,
      max: 200000,
    },
  ],
  commentary: null,
  MarketID: null,
  marketId: null,
  update_at: "1688297105",
  score: [],
  counter: 2,
  sessionCounter: 0,
};
