/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./PageHeader.css";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import useSocketUserdata from "../hooks/Sockets/useSocketUserdata";
import useLogout from "../../hooks/useLogout";
import Sidebar from "./SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function PageHeader(props) {
  let userId = "";
  userId = sessionStorage.getItem("UUID");
  const userPassword = sessionStorage.getItem("password");

  const socketData = useSocketUserdata(userId);
  const { mutate: logout } = useLogout();

  const payload = {
    user_id: userId,
    acc_type: "user",
  };

  useEffect(() => {
    if (socketData) {
      if (
        socketData.user.status === false ||
        socketData.user.status === "false" ||
        socketData.user.status === "0" ||
        socketData.user.status === 0
      ) {
        logout(payload, {
          onSuccess: (response) => {
            toast.error("You have been logged out from this device.");

            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
          },
          onError: (error) => {
            console.error(error);
          },
        });
      } else if (userPassword !== socketData.user.password) {
        logout(payload, {
          onSuccess: (response) => {
            toast.error("You have been logged out from this device.");

            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
          },
          onError: (error) => {
            console.error(error);
          },
        });
      } else {
        setData(socketData.user);
        if (props?.setCassinoData) {
          props?.setCassinoData(socketData.cassino);
          props?.setUserData(socketData.user);
        }
        if (props?.setGameData && props?.setUserData) {
          if (props?.game === "Teen Patti") {
            props?.setGameData(socketData.cassino[3]);
            props?.setUserData(socketData.user);
          } else if (props?.game === "Andar Bahar") {
            props?.setGameData(socketData.cassino[1]);
            props?.setUserData(socketData.user);
          } else if (props?.game === "Lucky 7") {
            props?.setGameData(socketData.cassino[2]);
            props?.setUserData(socketData.user);
          } else if (props?.game === "Dragon Tiger") {
            props?.setGameData(socketData.cassino[0]);
            props?.setUserData(socketData.user);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  const [data, setData] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <table
        width="100%"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{
          backgroundColor: "rgb(29, 29, 29)",
          height: "65px",
        }}
      >
        <tbody>
          <tr>
            <td width="100" className="pl-4" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faBars}
                  size="2xl"
                  style={{ color: "#ffffff", marginRight: "20px" }}
                  onClick={toggleSidebar}
                />
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <Link to="/MainMenu">
                  <h1 id="acelogosidebar1">10XBAT</h1>{" "}
                </Link>
              </div>
            </td>
            <td
              style={{ verticalAlign: "end" }}
              className="FontTextWhite"
              align="end"
            >
              <div className="profile_picture d-flex flex-column align-items-end">
                <p
                  align="end"
                  className="mb-1 pr-4 pt-2"
                  style={{ textTransform: "uppercase", fontSize: "12px" }}
                >
                  {data ? data.name : ""} : {data ? data.code : ""}
                </p>
                <p
                  align="end"
                  className="mb-1 pr-4"
                  style={{ fontSize: "12px" }}
                >
                  Coins: {data ? Math.round(data.limit) : 0}
                  {/* Display wallet balance */}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
