import React, { useState, useEffect } from "react";
import "./UserInplay.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useGetCompleteMatchInfo from "./hooks/useGetCompleteMatchInfo";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";

export default function UserCompletegame() {
  const location = useLocation();
  const navigate = useNavigate();
  const UrlSearchParams = new URLSearchParams(location.search);
  let matchData = [];

  matchData = location.state;

  let userId = sessionStorage.getItem("UUID");
  const matchId = UrlSearchParams.get("id");

  const { mutate: getCompleteMatchInfo } = useGetCompleteMatchInfo();

  const [data, setData] = useState([]);
  const [finalAmount, setFinalAmount] = useState(0);
  const [finalAmountSession, setFinalAmountSession] = useState(0);
  const [myCommission, setMyCommission] = useState(0);
  const [matchSessionPlusMinus, setMatchSessionPlusMinus] = useState(0);
  const [amountAfterCommission, setAmountAfterCommission] = useState(0);
  const [netPlusMinus, setNetPlusMinus] = useState(0);

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }

    if (userId && matchId) {
      const payload = {
        user_id: userId,
        match_id: matchId,
        requirement: "clientPanelCompleteMatch",
      };

      let final_amt = 0;
      let session_win_amt = 0;
      let match_session_plus_minus = 0;
      let amount_after_comm = 0;
      let my_comm = 0;
      let net_plus_minus = 0;

      getCompleteMatchInfo(payload, {
        onSuccess: (response) => {
          setData(response.data);
          console.log(response);

          let ledgerData = response.data.ledgerData;

          ledgerData = ledgerData.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          final_amt = ledgerData[0].m_amt * -1;
          session_win_amt = ledgerData[0].s_amt * -1;
          match_session_plus_minus = final_amt + session_win_amt;
          my_comm = ledgerData[0].c_com;
          amount_after_comm = ledgerData[0].final * -1;
          net_plus_minus = ledgerData[0].final * -1;

          setFinalAmount(final_amt);
          setFinalAmountSession(session_win_amt);
          setMatchSessionPlusMinus(match_session_plus_minus);
          setMyCommission(my_comm);
          setAmountAfterCommission(amount_after_comm);
          setNetPlusMinus(net_plus_minus);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, matchId]);

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ overflowY: "hidden" }}
      >
        <div className="position-relative">
          <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <PageHeader title="COMPLETE GAME" />
          </div>

          <div className="menu" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>

          <div className="container-fluid p-0 pt-2  complategame">
            <table
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              style={{ background: "#e9e9e9" }}
            >
              <tbody>
                <tr>
                  <td valign="top">
                    <table
                      width="100%"
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <tbody>
                        <tr>
                          <td align="left" valign="top">
                            <table
                              width="100%"
                              border="0"
                              bgcolor="#FF471A"
                              cellSpacing="0"
                              cellPadding="0"
                              className="mb-0"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    height="35"
                                    align="center"
                                    className="TeamCombo mb-0"
                                    style={{ backgroundColor: "#002D5B" }}
                                  >
                                    <p
                                      style={{
                                        color: "#FFF",
                                        fontFamily:
                                          "Verdana, Geneva, sans-serif",
                                        fontSize: "17px",
                                        fontWeight: "bold",
                                      }}
                                      className="mb-0"
                                      id="completegamematchnamesingal"
                                    >
                                      {matchData?.name}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0 text-center py-2"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontSize: "16px",
                                fontWeight: 600,
                                fontFamily: "Verdana, Geneva, sans-serif",
                                marginTop: "10px",
                              }}
                              id="completegamematchnamesingal"
                            >
                              Match Bet(s) WON BY :{" "}
                              {matchData?.won_by === "A"
                                ? matchData?.team1
                                : matchData?.won_by === "B"
                                ? matchData?.team2
                                : "DRAW"}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td
                            align="center"
                            valign="top"
                            style={{ paddingTop: "8px" }}
                          >
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontFamily: "Verdana, Geneva, sans-serif",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            ></p>

                            <table
                              width="100%"
                              border="0"
                              cellPadding="2"
                              cellSpacing="2"
                              id="MyBets"
                              className="complategametable align-middle"
                            >
                              <tbody>
                                <tr bgcolor="#002D5B">
                                  <td
                                    height="25"
                                    className="FontTextWhite10px p-2"
                                    style={{
                                      lineHeight: "20px",

                                      textAlign: "end",
                                    }}
                                    id="completegamematchnamesingal11"
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    style={{
                                      lineHeight: "20px",

                                      textAlign: "end",
                                    }}
                                    id="completegamematchnamesingal11"
                                  >
                                    Amount
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    style={{
                                      lineHeight: "20px",

                                      textAlign: "center",
                                    }}
                                    id="completegamematchnamesingal11"
                                  >
                                    Mode
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    style={{
                                      lineHeight: "20px",

                                      textAlign: "center",
                                    }}
                                    id="completegamematchnamesingal11"
                                  >
                                    Team
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    style={{
                                      lineHeight: "20px",

                                      textAlign: "end",
                                    }}
                                    id="completegamematchnamesingal11"
                                  >
                                    {matchData?.team1}
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    style={{
                                      lineHeight: "20px",

                                      textAlign: "end",
                                    }}
                                    id="completegamematchnamesingal11"
                                  >
                                    {matchData?.team2}
                                  </td>
                                </tr>

                                {data.betting?.length > 0 ? (
                                  data.betting.map((bets, index) => (
                                    <tr bgcolor="#FFF" key={index}>
                                      <td
                                        height="45"
                                        className="FontText px-2 py-1"
                                        style={{
                                          lineHeight: "20px",
                                          textAlign: "end",
                                        }}
                                        id="completegamematchnamesingal22"
                                      >
                                        {bets.betTeam === "A"
                                          ? bets.type === "lay"
                                            ? bets.teamA_lay_rate
                                            : bets.teamA_back_rate
                                          : bets.type === "lay"
                                          ? bets.teamB_lay_rate
                                          : bets.teamB_back_rate}
                                      </td>

                                      <td
                                        className="FontText px-2 py-1"
                                        style={{
                                          lineHeight: "20px",
                                          textAlign: "end",
                                        }}
                                        id="completegamematchnamesingal22"
                                      >
                                        {bets.amount}
                                      </td>

                                      <td
                                        className="FontText px-2 py-1"
                                        style={{
                                          lineHeight: "20px",
                                          textAlign: "center",
                                        }}
                                        id="completegamematchnamesingal22"
                                      >
                                        {bets.type === "lay" ? "Khai" : "Lagai"}
                                      </td>

                                      <td
                                        className="FontText px-2 py-1"
                                        style={{
                                          lineHeight: "20px",
                                          textAlign: "center",
                                        }}
                                        id="completegamematchnamesingal22"
                                      >
                                        {bets.betTeam === "A"
                                          ? matchData?.team1
                                          : matchData?.team2}
                                      </td>

                                      <td
                                        className="FontText px-2 py-1"
                                        style={{
                                          lineHeight: "20px",
                                          textAlign: "end",
                                        }}
                                        id="completegamematchnamesingal22"
                                      >
                                        {bets.type === "lay"
                                          ? Number(bets.teamA_lay_amount)
                                          : Number(bets.teamA_bet_amount)}
                                      </td>

                                      <td
                                        className="FontText px-2 py-1"
                                        style={{
                                          lineHeight: "20px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {bets.type === "lay"
                                          ? Number(bets.teamB_lay_amount)
                                          : Number(bets.teamB_bet_amount)}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr bgcolor="#fff">
                                    <td
                                      colSpan="6"
                                      height="25"
                                      className="FontText text-center px-2 py-1"
                                    >
                                      No Bets found
                                    </td>
                                  </tr>
                                )}

                                <tr bgcolor="#FFF">
                                  <td
                                    height="25"
                                    colSpan="6"
                                    align="center"
                                    className="FontText"
                                    style={{
                                      color: finalAmount >= 0 ? "#00F" : "#F00",
                                    }}
                                  >
                                    {finalAmount >= 0 ? "You Won" : "You Lost"}{" "}
                                    {finalAmount}/- Coins.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td
                            align="center"
                            valign="top"
                            style={{ paddingTop: "8px" }}
                          >
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontFamily: "Verdana, Geneva, sans-serif",
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginTop: "10px",
                              }}
                              id="completegameteamcolor"
                            >
                              Session Bet(s)
                            </p>

                            <table
                              width="100%"
                              border="0"
                              cellSpacing="2"
                              cellPadding="2"
                              className="complategametable align-middle mt-2"
                            >
                              <tbody>
                                <tr bgcolor="#002D5B">
                                  <td
                                    height="25"
                                    className="FontTextWhite10px p-2"
                                    id="completegamematchnamesingal22"
                                  >
                                    Session
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    id="completegamematchnamesingal22"
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    id="completegamematchnamesingal22"
                                  >
                                    Amount
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    id="completegamematchnamesingal22"
                                  >
                                    Runs
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    id="completegamematchnamesingal22"
                                  >
                                    Mode
                                  </td>
                                  <td
                                    className="FontTextWhite10px p-2"
                                    id="completegamematchnamesingal22"
                                  >
                                    Dec
                                  </td>
                                </tr>

                                {data.sessionData?.length > 0 ? (
                                  data.sessionData.map((bets, index) => (
                                    <tr bgcolor="#FFF" key={index}>
                                      <td
                                        height="25"
                                        className="FontText px-2 py-1"
                                      >
                                        {bets.session}
                                      </td>

                                      <td className="FontText px-2 py-1">
                                        {bets.rate}
                                      </td>

                                      <td className="FontText px-2 py-1">
                                        {bets.amount}
                                      </td>

                                      <td className="FontText px-2 py-1">
                                        {bets.runs}
                                      </td>

                                      <td className="FontText px-2 py-1">
                                        {bets.mode}
                                      </td>

                                      <td className="FontText px-2 py-1">
                                        {bets.declare}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr bgcolor="#fff">
                                    <td
                                      colSpan="6"
                                      height="25"
                                      className="FontText text-center px-2 py-1"
                                    >
                                      No Bets found
                                    </td>
                                  </tr>
                                )}

                                <tr bgcolor="#FFF">
                                  <td
                                    height="25"
                                    colSpan="6"
                                    align="center"
                                    className="FontText"
                                    style={{
                                      color:
                                        finalAmountSession >= 0
                                          ? "#00F"
                                          : "#F00",
                                    }}
                                  >
                                    {finalAmountSession >= 0
                                      ? "You Won"
                                      : "You Lost"}{" "}
                                    {finalAmountSession}/- Coins.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td
                            valign="top"
                            align="center"
                            style={{ paddingTop: "8px" }}
                          >
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontFamily: "Verdana, Geneva, sans-serif",
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginTop: "10px",
                              }}
                              id="completegameteamcolor"
                            >
                              Match Session Plus Minus
                            </p>
                          </td>
                        </tr>
                        <tr bgcolor="#FFF">
                          <td
                            height="40"
                            align="center"
                            className="FontText"
                            style={{
                              color:
                                matchSessionPlusMinus >= 0 ? "#00F" : "#F00",
                            }}
                          >
                            {matchSessionPlusMinus >= 0
                              ? "You Won"
                              : "You Lost"}{" "}
                            {matchSessionPlusMinus}/- Coins.
                          </td>
                        </tr>

                        <tr>
                          <td
                            valign="top"
                            align="center"
                            style={{ paddingTop: "8px" }}
                          >
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontFamily: "Verdana, Geneva, sans-serif",
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginTop: "10px",
                              }}
                              id="completegameteamcolor"
                            >
                              My Commission
                            </p>
                          </td>
                        </tr>
                        <tr bgcolor="#FFF">
                          <td
                            height="25"
                            colSpan="6"
                            align="center"
                            className="FontText"
                            style={{ color: "#00F" }}
                          >
                            {myCommission}/- Coins.
                          </td>
                        </tr>

                        <tr>
                          <td
                            valign="top"
                            align="center"
                            style={{ paddingTop: "8px" }}
                          >
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontFamily: "Verdana, Geneva, sans-serif",
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginTop: "10px",
                              }}
                              id="completegameteamcolor"
                            >
                              Amount After Commission
                            </p>
                          </td>
                        </tr>
                        <tr bgcolor="#FFF">
                          <td
                            height="40"
                            align="center"
                            className="FontText"
                            style={{
                              color:
                                amountAfterCommission >= 0 ? "#00F" : "#F00",
                            }}
                          >
                            {amountAfterCommission >= 0
                              ? "You Won"
                              : "You Lost"}{" "}
                            {amountAfterCommission}/- Coins.
                          </td>
                        </tr>

                        <tr>
                          <td
                            valign="top"
                            align="center"
                            style={{ paddingTop: "8px" }}
                          >
                            <p
                              className="price-btn price-btn-blue popup-with-zoom-anim mb-0"
                              style={{
                                backgroundColor: "black",
                                color: "#FFF",
                                fontFamily: "Verdana, Geneva, sans-serif",
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginTop: "10px",
                              }}
                              id="completegameteamcolor"
                            >
                              Net Plus Minus
                            </p>
                          </td>
                        </tr>
                        <tr bgcolor="#FFF">
                          <td
                            height="40"
                            align="center"
                            className="FontText"
                            style={{
                              color: netPlusMinus >= 0 ? "#00F" : "#F00",
                            }}
                          >
                            {netPlusMinus >= 0 ? "You Won" : "You Lost"}{" "}
                            {netPlusMinus}/- Coins.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="menu mt-3" id="menu" align="center">
        <ul className="nav">
          <li className="active abc w-100">
            <Link
              to="/MainMenu"
              style={{ height: "32.1px", paddingBottom: "40px" }}
            >
              BACK TO MAIN MENU
            </Link>
          </li>
        </ul>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
